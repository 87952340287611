import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './SharedModule/shared/Guards/auth.guard';
import { RoleGuard } from './SharedModule/shared/Guards/role.guard';
import { SuperadminGuard } from './SharedModule/shared/Guards/superadmin.guard';
import { DetailsStaffComponent } from './SharedModule/shared/Components/staff/details-staff/details-staff.component';
import { SelectivePreloadingStrategy } from './app-selective-preloading';
import { NavigationBarComponent } from './CoreModule/Components/navigation-bar/navigation-bar.component';



const routes: Routes = [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    {
        path: '',

        loadChildren: () => import('./FeatureModules/user/users.module').then(m => m.UsersModule)

    },
    {
        path: '',
        component: NavigationBarComponent,

        canActivate: [AuthGuard], //, RoleGuard],

        children: [
            {
                path: 'admin',
                canActivate: [SuperadminGuard],


                children: [
                    {
                        path: '',
                        redirectTo: '/admin/schools',
                        pathMatch: 'full'
                    },
                    {
                        path: 'schools',

                        loadChildren: () => import('./FeatureModules/admin/schools/schools.module').then(m => m.SchoolsModule),

                        data: { preload: true, myvar: "appAdmin" }
                    },
                    {
                        path: 'staff',

                        loadChildren: () => import('./FeatureModules/admin/staff/staff.module').then(m => m.StaffModule),

                        data: { preload: true, myvar: "appAdmin" }
                    },
                    {
                        path: 'students',

                        loadChildren: () => import('./FeatureModules/admin/students/students.module').then(m => m.StudentsModule),

                        data: { preload: true, myvar: "appAdmin" }
                    },
                    {
                        path: 'uaperiod',

                        loadChildren: () => import('./FeatureModules/admin/ua-periods/ua-periods.module').then(m => m.UaPeriodsModule),

                        data: { preload: true, myvar: "appAdmin" }
                    },
                    {
                        path: 'reports',

                        loadChildren: () => import('./FeatureModules/admin/reports/reports.module').then(m => m.ReportsModule),

                        data: { preload: true, myvar: "appAdmin" }
                    },
                    {
                        path: 'resources',

                        loadChildren: () => import('./FeatureModules/admin/resources/resources.module').then(m => m.ResourcesModule),

                        data: { preload: true, myvar: "appAdmin" }
                    },

                ]
            },
            {
                path: 'rater',
                canActivate: [SuperadminGuard],

                children: [
                    {
                        path: '',
                        redirectTo: '/rater/uaperiod',
                        pathMatch: 'full'
                    },
                    {
                        path: 'progress-monitoring',
                        loadChildren: () => import('./FeatureModules/admin/progress-monitoring/progress-monitoring.module').then(m => m.ProgressMonitoringModule),
                        data: { preload: true, myvar: "appRater" }
                        // path: 'progress-monitoring',
                        // loadChildren: () => import('./FeatureModules/admin/progress-monitoring/progress-monitoring.module').then(m => m.ProgressMonitoringModule),
                        // data: { preload: true, myvar: "appRater" }
                    },
                    {
                        path: 'uaperiod',
                        loadChildren: () => import('./FeatureModules/rater/rater-ua-periods/rater-ua-periods.module').then(m => m.RaterUaPeriodsModule),
                        data: { preload: true, myvar: "appRater" }
                    },
                    {
                        path: 'other-reports',

                        loadChildren: () => import('./FeatureModules/rater/other-reports/other-reports.module').then(m => m.OtherReportsModule),

                        data: { preload: true, myvar: "appRater" }
                    },
                    {
                        path: 'students',

                        loadChildren: () => import('./FeatureModules/rater/rater-students/rater-students.module').then(m => m.RaterStudentsModule),

                        data: { preload: true, myvar: "appRater" }
                    },
                    {
                        path: 'resources',

                        loadChildren: () => import('./FeatureModules/rater/rater-resources/rater-resources.module').then(m => m.RaterResourcesModule),
                        data: { preload: true, myvar: "appRater" }
                    }

                ]
            },
            {
                path: 'accounts',
                canActivate: [SuperadminGuard],

                loadChildren: () => import('./FeatureModules/accounts/accounts.module').then(m => m.AccountsModule),

                data: { preload: true, myvar: "appSuperAdmin" }
            },
            {
                path: 'user/myAccount',
                canActivate: [SuperadminGuard, AuthGuard],
                component: DetailsStaffComponent
            }
        ]
    },
    {
        path: 'progress-monitoring/:planId/:token',

        loadChildren: () => import('./FeatureModules/assessment/assessment.module').then(m => m.AssessmentModule),
        data: { preload: true, myvar: "assessmentUser" }
    },
    {
        path: 'parent/:id/:parentId/:token',
        loadChildren: () => import('./FeatureModules/parent/parents.module').then(m => m.ParentsModule)
    },
    { path: '**', redirectTo: '/login' }
];


@NgModule({
    imports: [RouterModule.forRoot(
        routes,
        {
            preloadingStrategy: SelectivePreloadingStrategy
        }

    )],
    exports: [RouterModule]
})
export class AppRoutingModule { }

