// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
    width: 100%;
    border-bottom: 3px solid var(--borderColor);
    text-transform: uppercase;
    text-align: center;
}

.title h2 {
    margin-bottom: 0;
    font-weight: bold;
}

mat-dialog-content {
    text-align: center;
}

input[type="password"] {
    width: 100%;
}

.search-addon {
    position: relative;
}

.search-addon>div {
    width: 29px;
    height: 100%;
    position: absolute;
    right: 0;
    cursor: pointer;
}

.search-addon .material-icons {
    padding: 11px 5px;
    font-size: 16px;
    font-weight: bold;
}

.search-addon input {
    padding-right: 28px;
    padding-left: 10px;
}

table {
    width: 100%;
}

table td {
    padding: 5px 10px;
}

table td:first-child {
    width: 215px;
}

.alert {
    margin-top: 10px;
    clear: both;
}

button {
    margin: 5px 5px 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/SharedModule/shared/Components/staff/change-password/change-password.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,2CAA2C;IAC3C,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,QAAQ;IACR,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,oBAAoB;AACxB","sourcesContent":[".title {\r\n    width: 100%;\r\n    border-bottom: 3px solid var(--borderColor);\r\n    text-transform: uppercase;\r\n    text-align: center;\r\n}\r\n\r\n.title h2 {\r\n    margin-bottom: 0;\r\n    font-weight: bold;\r\n}\r\n\r\nmat-dialog-content {\r\n    text-align: center;\r\n}\r\n\r\ninput[type=\"password\"] {\r\n    width: 100%;\r\n}\r\n\r\n.search-addon {\r\n    position: relative;\r\n}\r\n\r\n.search-addon>div {\r\n    width: 29px;\r\n    height: 100%;\r\n    position: absolute;\r\n    right: 0;\r\n    cursor: pointer;\r\n}\r\n\r\n.search-addon .material-icons {\r\n    padding: 11px 5px;\r\n    font-size: 16px;\r\n    font-weight: bold;\r\n}\r\n\r\n.search-addon input {\r\n    padding-right: 28px;\r\n    padding-left: 10px;\r\n}\r\n\r\ntable {\r\n    width: 100%;\r\n}\r\n\r\ntable td {\r\n    padding: 5px 10px;\r\n}\r\n\r\ntable td:first-child {\r\n    width: 215px;\r\n}\r\n\r\n.alert {\r\n    margin-top: 10px;\r\n    clear: both;\r\n}\r\n\r\nbutton {\r\n    margin: 5px 5px 20px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
