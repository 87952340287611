
import { Component, OnInit, } from '@angular/core';
import { trigger, transition, style, animate } from "@angular/animations"
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Inject } from "@angular/core";
import { DOCUMENT } from '@angular/common';

import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { EnsureActionModalComponent } from '@app/SharedModule/shared/Components/ensure-action-modal/ensure-action-modal.component';
import { QuestionTextDTO, StudentFormDataRaterDTO, StudentFormDataParentDTO } from '@app/CoreModule/Models/Assessment';
import { StudentIdFullNameDTO } from '@app/CoreModule/Models/Student';
import { UaPeriodIdNameDTO } from '@app/CoreModule/Models/UAPeriod';
import { AuthService, AssessmentService, UaPeriodService } from '@app/CoreModule/Services';


@Component({
  selector: 'app-complete-form-student',
  templateUrl: './complete-form-student.component.html',
  styleUrls: ['./complete-form-student.component.css']
})
export class CompleteFormStudentComponent implements OnInit {

  SubmitTypes = {
    Submit: 1,
    SubmitAndNext: 2
  }

  period: UaPeriodIdNameDTO = {} as UaPeriodIdNameDTO;
  student: StudentIdFullNameDTO = {} as StudentIdFullNameDTO;
  form = { 'Comment': '' };
  sumbitType = 1;
  lockSubmit = false;

  radioOptions1 = [
    { Name: "Never", Value: 1 },
    { Name: "Rarely", Value: 2 },
    { Name: "Sometimes", Value: 3 },
    { Name: "Often", Value: 4 },
    { Name: "Very Often", Value: 5 },
  ];

  radioOptions2 = [
    { Name: "Yes", Value: 1 },
    { Name: "No", Value: 0 },
    { Name: "Not Sure", Value: -1 }
  ];

  radioOptions3 = [
    { Name: "Never", Value: 5 },
    { Name: "Rarely", Value: 4 },
    { Name: "Sometimes", Value: 3 },
    { Name: "Often", Value: 2 },
    { Name: "Very Often", Value: 1 },
  ];

  radioOptions4 = [
    { Name: "Yes", Value: 0 },
    { Name: "No", Value: 1 },
    { Name: "Not Sure", Value: 1.00001 }
  ];
  questions: QuestionTextDTO[] = [];

  constructor(private authService: AuthService, public dialog: MatDialog, private location: Location, private router: Router, @Inject(DOCUMENT) private document: Document, private assessmentService: AssessmentService, private route: ActivatedRoute) {
    var studentId = +this.route.snapshot.paramMap.get('id');
    this.assessmentService.getRaterAssessmentData(studentId).subscribe({
      next: (result: StudentFormDataRaterDTO) => {
        this.setDataToObjects(result);
        this.lockSubmit = false;
      },
      error: (error) => {
        this.lockSubmit = true;
        if ((error.status == 400 || error.status == 500) && typeof error.error == "string") {
          this.authService.alertError(error.error);
        }
        else {
          this.authService.alertError('An error occured while requesting this student\'s data from server. Please try again.');
        }
      }
    });
  }

  ngOnInit() {
  }

  getOptions(question: QuestionTextDTO) {

    if (parseInt(question.QuestionId) < 24) {
      return this.radioOptions1
    } else if (parseInt(question.QuestionId) == 30) {
      return this.radioOptions4
    } else if (parseInt(question.QuestionId) >= 24 && parseInt(question.QuestionId) <= 29 && parseInt(question.QuestionId) != 30) {
      return this.radioOptions3
    } else if (parseInt(question.QuestionId) > 30) {
      return this.radioOptions2
    }
  }

  setDataToObjects(result: StudentFormDataRaterDTO) {
    this.period = result['Period'];
    this.student = result['Student'];
    this.questions = result['Questions'];
    result['Answers'].forEach(answer => {
      this.form[answer['QuestionId']] = answer['Answer'];
    });
    this.form['Comment'] = result['Comment'];
  }

  submitHandler(formElement: NgForm) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    const dialogRef = this.dialog.open(EnsureActionModalComponent, {
      width: '600px',
      data: `Are you sure that you want to submit the questionnaire for '${this.student.FullName}'?`
    });
    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        if (this.sumbitType == this.SubmitTypes.Submit) {
          this.submit();
        }
        else if (this.sumbitType == this.SubmitTypes.SubmitAndNext) {
          this.submitAndNext(formElement);
        }
      }
    });
  }

  submit() {
    var data = this.prepareData();
    this.lockSubmit = true;
    this.assessmentService.submitRaterAssessment(data).subscribe({
      next: (result) => {
        this.authService.alertSuccess('Univesal Assessment has been succesfully submitted.');
        this.lockSubmit = false;
        this.goBack();
      },
      error: (error) => {
        this.lockSubmit = false;
        if ((error.status == 400 || error.status == 500) && typeof error.error == "string") {
          this.authService.alertError(error.error);
        }
        else {
          this.authService.alertError('Universal Assessment has failed to be submitted. Please try again.');
        }
      }
    })
  }


  submitAndNext(formElement: NgForm) {
    var data = this.prepareData();
    this.lockSubmit = true;
    this.assessmentService.submitAndNextRaterAssessment(data).subscribe({
      next: (result: StudentFormDataRaterDTO) => {
        this.authService.alertSuccess('Univesal Assessment has been succesfully submitted.');
        this.lockSubmit = false;
        if (result) {
          this.form = { 'Comment': '' };
          formElement.reset();
          formElement.resetForm();
          document.getElementById('body').scrollTop = 0;
          this.router.navigate(['/rater/uaperiod/students/' + result['Student']['Id'].toString() + '/form']);
          this.setDataToObjects(result);
        }
        else this.router.navigate(['/rater/uaperiod']);
      },
      error: (error) => {
        this.lockSubmit = false;
        if ((error.status == 400 || error.status == 500) && typeof error.error == "string") {
          this.authService.alertError(error.error);
        }
        else {
          this.authService.alertError('Universal Assessment has failed to be submitted. Please try again.');
        }
      },
    })
  }

  save() {
    var data = this.prepareData();
    this.lockSubmit = true;
    this.assessmentService.saveRaterAssessment(data).subscribe({
      next: (result) => { this.authService.alertSuccess('Universal Assessment has been successfully saved.'); this.lockSubmit = false; },
      error: () => { this.authService.alertError('Universal Assessment has failed to be saved.'); this.lockSubmit = false; },
    })
  }

  prepareData() {
    var answers = [];
    Object.keys(this.form).forEach(key => {
      if (key != "Comment") {
        let value = +this.form[key];
        const timestamp = Date.now();
        const today = new Date(timestamp);
        answers.push({ QuestionId: key, Answer: value, TimeStamp: null });
      }
    })

    return {
      StudentId: this.student.Id,
      PeriodId: this.period.Id,
      Answers: answers,
      Comment: this.form['Comment']
    }
  }

  goBack() {
    var loc = window.location.href.split('/');
    if (loc.indexOf('admin') != -1) this.location.back();
    else if (loc.indexOf('rater') != -1) this.router.navigate([`/rater/uaperiod`]);
  }
}
