import { Component, OnInit, Inject } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';

import { AuthService, StaffService } from '@app/CoreModule/Services';
import 'rxjs';


@Component({
    selector: 'app-activate-member-staff',
    templateUrl: './activate-member-staff.component.html',
    styleUrls: ['./activate-member-staff.component.css']
})
export class ActivateMemberStaffComponent implements OnInit {

    adminId: number = 0;

    constructor(private authService: AuthService, private staffService: StaffService, public thisDialogRef: MatDialogRef<ActivateMemberStaffComponent>, @Inject(MAT_DIALOG_DATA) public data: number) { }

    ngOnInit() {
        this.adminId = this.data;
    }

    onCloseConfirm() {
        this.staffService.activateStaff(this.adminId).subscribe({
            next: (result) => this.thisDialogRef.close(true),
            error: (error) => {
                if ((error.status == 400 || error.status == 500) && typeof error.error == "string") {
                    this.authService.alertError(error.error)
                }
                else {
                    this.authService.alertError("An error occured while activating staff member. Please try again.")
                }
            }
        })
    }

    onCloseCancel() {
        this.thisDialogRef.close(false);
    }
}
