// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-form-field {
    width: 270px;
}`, "",{"version":3,"sources":["webpack://./src/app/SharedModule/shared/Components/students/periods-line-chart/periods-line-chart.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB","sourcesContent":["mat-form-field {\r\n    width: 270px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
