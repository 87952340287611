import { Component, OnInit, Inject } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';

import 'rxjs';

@Component({
    selector: 'app-login-link-staff',
    templateUrl: './login-link-staff.component.html',
    styleUrls: ['./login-link-staff.component.css']
})
export class LoginLinkStaffComponent implements OnInit {

    constructor(public thisDialogRef: MatDialogRef<LoginLinkStaffComponent>, @Inject(MAT_DIALOG_DATA) public data: string) { }

    ngOnInit() {
    }

    onCloseConfirm() {
        this.thisDialogRef.close(true);
    }

    onCloseCancel() {
        this.thisDialogRef.close(false);
    }

}
