import { Component, Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { UserEmail, SetupInfo } from '../Models/User';

import { map, shareReplay } from 'rxjs/operators';



@Injectable()
export class UsersService {

    constructor(private http: HttpClient, @Inject('BASE_URL') public baseUrl: string) { }

    requestResetPassword(data) {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this.http.post(this.baseUrl + 'api/Users/RequestResetPassword', data, { headers: headers, responseType: 'text' }).pipe(map(result => {
            return result;
        })).pipe(shareReplay());

    }

    setupPassword(data) {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this.http.post(this.baseUrl + 'api/Users/Setup', data, { headers: headers, responseType: 'text' }).pipe(map(result => {
            return result;
        })).pipe(shareReplay());

    }

    getSetupInfo(id, token) {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this.http.get<UserEmail>(this.baseUrl + `api/Users/GetSetupInfo/${id}/${token}`, { headers: headers }).pipe(map(result => {
            return result;
        })).pipe(shareReplay());

    }

    getLoggedInSetupInfo() {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this.http.get<SetupInfo>(this.baseUrl + `api/Users/GetLoggedInSetupInfo`, { headers: headers }).pipe(map(result => {
            return result;
        })).pipe(shareReplay());

    }

    changePassword(data) {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this.http.post(this.baseUrl + 'api/Users/ChangePassword', data, { headers: headers, responseType: 'text' }).pipe(map(result => {
            return result;
        })).pipe(shareReplay());

    }

    setAgreedOnTerms() {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this.http.post(this.baseUrl + 'api/Users/SetAgreedOnTerms', {}, { headers: headers, responseType: 'text' }).pipe(map(result => {
            return result;
        })).pipe(shareReplay());

    }

    /*
    getUserFullNameByEmail(email) {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(this.baseUrl + 'api/Users/GetFullNameByEmail', { Email: email }, { headers: headers, responseType: 'text' }).map(result => {
            return result;
        }).shareReplay();
    }*/

    getAccountName() {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this.http.get(this.baseUrl + `api/Users/GetAccountName`, { headers: headers, responseType: 'text' }).pipe(map(result => {
            return result;
        })).pipe(shareReplay());

    }

    getHelpFile(fileId: number) {
        return this.http.request(new HttpRequest('GET', this.baseUrl + `api/Files/GetHelpFile/${fileId}`, null, { reportProgress: true, responseType: 'blob' })).toPromise();
    }


    getFontNormal() {
        return this.http.request(new HttpRequest('GET', this.baseUrl + `api/Files/GetFontNormal`, null, { responseType: 'blob' })).toPromise();
    }

    getFontBold() {
        return this.http.request(new HttpRequest('GET', this.baseUrl + `api/Files/GetFontBold`, null, { responseType: 'blob' })).toPromise();
    }
}

