import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { 
  UAPeriodDTO, EmailRequestDTO, UAPeriodMainDTO, UAPeriodDetailsDTO, 
  UAPeriodEditDTO, SchoolPeriodEditDataDTO, UAPeriodSchoolEditDTO 
} from '../Models/UAPeriod';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root' // Use providedIn root for singleton service
})
export class UaPeriodService {
  private apiUrl = ''

  constructor(private http: HttpClient, @Inject('BASE_URL') public baseUrl: string) { 
    this.apiUrl = `${this.baseUrl}api/UAPeriod/`;
  }

  getUAPeriodsMain(): Observable<UAPeriodMainDTO[]> {
    return this.http.get<UAPeriodMainDTO[]>(`${this.apiUrl}GetAllUAPeriodsMain`).pipe(
      shareReplay()
    );
  }

  getUAPeriodByIdEdit(id: number): Observable<UAPeriodEditDTO> {
    return this.http.get<UAPeriodEditDTO>(`${this.apiUrl}GetUAPeriodByIdEdit/${id}`).pipe(
      shareReplay()
    );
  }

  getUAPeriodDetailsById(id: number): Observable<UAPeriodDetailsDTO> {
    return this.http.get<UAPeriodDetailsDTO>(`${this.apiUrl}GetUAPeriodDetailsById/${id}`).pipe(
      shareReplay()
    );
  }

  getSchoolPeriodByIdEdit(periodId: number, schoolId: number): Observable<SchoolPeriodEditDataDTO> {
    return this.http.get<SchoolPeriodEditDataDTO>(`${this.apiUrl}GetSchoolPeriodByIdEdit/${periodId}/${schoolId}`).pipe(
      shareReplay()
    );
  }

  updateSchoolPeriod(period: UAPeriodSchoolEditDTO): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}UpdateSchoolPeriod`, period).pipe(
      shareReplay()
    );
  }

  getUAPeriods(): Observable<UAPeriodDTO[]> {
    return this.http.get<UAPeriodDTO[]>(`${this.apiUrl}GetAllUAPeriods`).pipe(
      shareReplay()
    );
  }

  getUAPeriodById(id: number): Observable<UAPeriodDTO> {
    return this.http.get<UAPeriodDTO>(`${this.apiUrl}GetUAPeriodById/${id}`).pipe(
      shareReplay()
    );
  }

  getPeriodEmailRequests(id: number): Observable<EmailRequestDTO[]> {
    return this.http.get<EmailRequestDTO[]>(`${this.apiUrl}GetPeriodEmailRequests/${id}`).pipe(
      shareReplay()
    );
  }

  addUAPeriod(period: UAPeriodDTO): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}Add`, period).pipe(
      shareReplay()
    );
  }

  updateUAPeriod(period: UAPeriodDTO): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}Update`, period).pipe(
      shareReplay()
    );
  }

  calculateUAPeriodResults(id: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}CalculateResults/${id}`).pipe(
      shareReplay()
    );
  }

  getCurrentUAPeriod(): Observable<UAPeriodDTO> {
    return this.http.get<UAPeriodDTO>(`${this.apiUrl}GetCurrentUAPeriod`).pipe(
      shareReplay()
    );
  }

  getCurrentOrLastUAPeriod(): Observable<UAPeriodDTO> {
    return this.http.get<UAPeriodDTO>(`${this.apiUrl}GetCurrentOrLastUAPeriod`).pipe(
      shareReplay()
    );
  }

  getLastUAPeriod(): Observable<UAPeriodDTO> {
    return this.http.get<UAPeriodDTO>(`${this.apiUrl}GetLastUAPeriod`).pipe(
      shareReplay()
    );
  }

  getFuturePeriods(): Observable<UAPeriodDTO[]> {
    return this.http.get<UAPeriodDTO[]>(`${this.apiUrl}GetFutureUAPeriods`).pipe(
      shareReplay()
    );
  }
}

