import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';

import 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AccountMainDTO, AccountEditDTO, AccountDetailsDTO, AccountToken, AccountLicensesDTO, ExportAnswersDTO } from '../Models/Account';
import { map, shareReplay } from 'rxjs/operators';


@Injectable()
export class AccountsService {

  accAdded = '';

  constructor(private http: HttpClient, public router: Router, @Inject('BASE_URL') public baseUrl: string) { }

  getAccountsMain() {

    return this.http.get<Array<AccountMainDTO>>(this.baseUrl + 'api/Accounts/GetAllAccountsMain')
      .pipe(
        map(result => result),
        shareReplay()
      );
  }

  getAccountByIdEdit(id: number) {
    return this.http.get<AccountEditDTO>(this.baseUrl + `api/Accounts/GetAccountByIdEdit/${id}`)
      .pipe(
        map(result => result),
        shareReplay()
      );
  }

  getAccountDetailsById(id: number) {
    return this.http.get<AccountDetailsDTO>(this.baseUrl + `api/Accounts/GetAccountDetailsById/${id}`)
      .pipe(
        map(result => result),
        shareReplay()
      );
  }

  addAccount(account: AccountEditDTO) {
    return this.http.post(this.baseUrl + 'api/Accounts/Add', account)
      .pipe(shareReplay());
  }

  updateAccount(account: AccountEditDTO) {
    return this.http.post(this.baseUrl + 'api/Accounts/Update', account)
      .pipe(shareReplay());

  }

  resendSetupAccountEmail(accountId: number) {
    return this.http.post(this.baseUrl + 'api/Accounts/ResendSetupAccountEmail', accountId)

      .pipe(shareReplay());
  }

  sendRenewalEmail(id: number) {
    return this.http.post(this.baseUrl + 'api/Accounts/SendRenewalEmail', id)
      .pipe(shareReplay());
  }

  getAccountLicenses(id: number) {
    return this.http.get<AccountLicensesDTO>(this.baseUrl + `api/Accounts/GetAccountLicenses/${id}`)
      .pipe(
        map(result => result),
        shareReplay()
      );

  }

  addAccountLicenses(accountId: number, licenses: number) {
    return this.http.post<AccountDetailsDTO>(this.baseUrl + `api/Accounts/AddAccountLicenses/${accountId}/${licenses}`, {})

      .pipe(shareReplay());
  }

  loginAsAccountAdmin(id: number) {
    return this.http.post<AccountToken>(this.baseUrl + 'api/Accounts/LoginToAccount', id)
      .pipe(shareReplay());
  }

  getAnswersForNorms(type: string) {
    return this.http.get<ExportAnswersDTO[]>(this.baseUrl + `api/Accounts/GetAnswersForNorms/${type}`)
      .pipe(
        map(result => result),
        shareReplay()
      );
  }

}
