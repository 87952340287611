import { Component, OnInit } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';


import { MatDialog, MatDialogConfig } from "@angular/material/dialog";

import * as $ from 'jquery';
import { trigger, style, animate, transition } from '@angular/animations';
import { DeactivateStudentComponent } from '../deactivate-student/deactivate-student.component';
import { ActivateStudentComponent } from '../activate-student/activate-student.component';
import { DeleteFileApprovalComponent } from '../delete-file-approval/delete-file-approval.component';

import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { HttpRequest, HttpEventType, HttpClient } from '@angular/common/http';

import { Globals } from '@app/globals';
import { StudentUAFullResultsDTO } from '@app/CoreModule/Models/Assessment';
import { CustomRoles } from '@app/CoreModule/Models/CustomRoles';
import { StudentDetailsDTO, StudentDetailsRaterDTO, StudentFileDTO } from '@app/CoreModule/Models/Student';
import { AuthService, AssessmentService, StudentsService, UaPeriodService } from '@app/CoreModule/Services';
import { StaffMainDTO } from '@app/CoreModule/Models/Staff';
import { UAPeriod, UAPeriodDTO } from '@app/CoreModule/Models/UAPeriod';

@Component({
    selector: 'app-details-students',
    templateUrl: './details-students.component.html',
    styleUrls: ['./details-students.component.css'],
    animations: [
        trigger(
            'myAnimation',
            [
                transition(
                    ':enter', [
                    style({ opacity: 0 }),
                    animate('500ms', style({ 'opacity': 1 }))
                ]
                )]
        ),
        trigger(
            'fadeInOut', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('500ms', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('200ms', style({ opacity: 0 }))
            ])
        ]
        )
    ]
})
export class DetailsStudentsComponent implements OnInit {

    student: StudentDetailsDTO = {} as StudentDetailsDTO;
    uaDataRater: StudentUAFullResultsDTO[] = [];
    uaDataParent: StudentUAFullResultsDTO[] = [];

    showServiceInfo = false;
    showParents = false;
    showManualInfo = false;
    showGraph = false;
    showUAData = false;
    showUADataRater = false;
    showUADataParent = false;
    showLocalNorms = false;
    showNationalNorms = false;
    showOtherBehavioralData = false;
    showUATableAfterData = false;
    showDetails = {};
    showDetailsTableOnScroll = true;

    loading = true;
    successfulDeactivation = false;
    successfulActivation = false;

    allowAssessment = false;
    allow123 = false;
    isRater = false;
    Rater: any;

    otherData: StudentFileDTO[] = [];
    newFile = { 'Id': 0, 'FileName': '', 'File': null };

    categoriesOuter = { 'TscoreBasicReading': 'Basic Reading', 'TscoreReadingComprehension': 'Reading Comprehension', 'TscoreWriting': 'Writing', 'TscoreExecutiveSkills': 'Executive Skills', 'DyslexiaTscoreTotal': 'Total Dyslexia Score' }

    public progress: number;
    public messageType: boolean = false;
    public messageText: string;

    constructor(public globals: Globals, private sanitizer: DomSanitizer, private http: HttpClient, private location: Location, private authService: AuthService, private assessmentService: AssessmentService, private studentsService: StudentsService, public dialog: MatDialog, private route: ActivatedRoute, private router: Router) {
        var role = this.authService.getRole();
        this.allow123 = (role === CustomRoles.DistrictAdministrator || role === CustomRoles.DistrictStaff || role === CustomRoles.SchoolAdministrator)
        this.isRater = role === CustomRoles.RaterOnly;
    }

    ngOnInit() {
        var id = +this.route.snapshot.paramMap.get('id');
        if (id) {
            this.studentsService.getStudentDetailsById(id).subscribe({
                next: (student: StudentDetailsDTO) => {
                    this.student = student;
                    this.Rater = student['Rater'];
                    this.fixServiceInfoData(student.ServiceInfo);
                    this.getFiles();

                    this.assessmentService.getStudentUAResults(id).subscribe({
                        next: (result: StudentUAFullResultsDTO[]) => {
                            this.uaDataRater = result['DataRater'];
                            this.uaDataParent = result['DataParent'];
                            this.showUATableAfterData = true;
                        },
                        error: (error) => {
                            this.uaDataRater = [];
                            this.showUATableAfterData = false;
                            if ((error.status == 400 || error.status == 500) && typeof error.error == "string") {
                                this.authService.alertError(error.error);
                            }
                            else {
                                this.authService.alertError("An error occured.");
                            }
                        },
                    })
                },
                error: () => this.authService.alertError(`An error occured while requesting data for this student. Please try again.`),
                complete: () => this.loading = false
            });

            this.assessmentService.getAssessmentStatus(id).subscribe({
                next: (result: boolean) => {
                    this.allowAssessment = result ? true : false;
                },
                error: () => this.allowAssessment = false
            });
        } else window.location.href = "/admin/students";

        this.scrollEventForStudentDetails();

    }

    fixServiceInfoData(info) {
        var mtss = this.studentsService.getMTSSValues().filter(value => value.Id == info.MTSS)[0];
        if (mtss) this.student.ServiceInfo.MTSS = mtss.Value;

        var disabilities = this.studentsService.getDisabilities().filter(value => value.Id == info.Disability)[0];
        if (disabilities) this.student.ServiceInfo.Disability = disabilities.Name;

        var mealStatus = this.studentsService.getMealStatus().filter(value => value.Id == info.MealStatus)[0];
        if (mealStatus) this.student.ServiceInfo.MealStatus = mealStatus.Name;

        var intervention = this.studentsService.getInterventionTypes().filter(value => value.Id == info.Intervention)[0];
        if (intervention) this.student.ServiceInfo.Intervention = intervention.Name;
    }

    changeDateFormat(datetime) {
        if (datetime) {
            let date = datetime.toString().split('T')[0].split('-');
            let newDate = `${date[1]}/${date[2]}/${date[0]}`;
            return newDate;
        }
    }

    sanitize(url: string) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    goToFormRater() {
        var id = +this.route.snapshot.paramMap.get('id');
        this.router.navigate([`/admin/students/${id}/assessment`]);
    }

    scrollEventForStudentDetails() {
        $('div#body').scroll(function () {
            var element = document.getElementById("student_table");
            if (element) {
                var rect = element.getBoundingClientRect();
                if (
                    rect.top >= - element.offsetHeight &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight + element.offsetHeight || document.documentElement.clientHeight + element.offsetHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                ) {
                    $("div#student_details_onscrolldown").hide();
                }
                else {
                    $("div#student_details_onscrolldown").show();
                }
            }
            else $('div#body').unbind('scroll');
        });
    }

    sendParentEmail(parentId) {
        this.studentsService.sendParentEmail(parentId).subscribe({
            next: () => {
                this.authService.alertSuccess("Email successfully sent.");
            },
            error: (error) => {
                if ((error.status == 400 || error.status == 500) && typeof error.error == "string") {
                    this.authService.alertError(error.error);
                }
                else {
                    this.authService.alertError("An error occured.");
                }
            }
        });
    }

    getParentLink(parentId) {
        this.studentsService.getParentLink(this.student.Id, parentId).subscribe({
            next: (result) => {
                window.open(`${location.origin}/#${result['Link']}`, '_blank');
            },
            error: (error) => {
                if ((error.status == 400 || error.status == 500) && typeof error.error == "string") {
                    this.authService.alertError(error.error);
                }
                else {
                    this.authService.alertError("An error occured.");
                }
            }
        });
    }

    openDialog(dial, file = null) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        if (dial == "deactivate") {
            const dialogRef = this.dialog.open(DeactivateStudentComponent, {
                width: 'auto',
                data: this.student['Id']
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.studentsService.deactivateStudent(this.student['Id']).subscribe({
                        next: (result) => {
                            this.student['Active'] = false;
                            this.successfulDeactivation = true;
                            this.successfulActivation = false;
                        },
                        error: (error) => {
                            if ((error.status == 400 || error.status == 500) && typeof error.error == "string") {
                                this.authService.alertError(error.error)
                            }
                            else {
                                this.authService.alertError(`An error occured while deactivating this student. Please try again.`)
                            }
                        }
                    })
                }
            });
        }
        else if (dial == "activate") {
            const dialogRef = this.dialog.open(ActivateStudentComponent, {
                width: 'auto',
                data: this.student['Id']
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.studentsService.activateStudent(this.student['Id']).subscribe({
                        next: (result) => {
                            this.student['Active'] = true;
                            this.successfulDeactivation = false;
                            this.successfulActivation = true;
                        },
                        error: (error) => {
                            if ((error.status == 400 || error.status == 500) && typeof error.error == "string") {
                                this.authService.alertError(error.error)
                            }
                            else {
                                this.authService.alertError(`An error occured while activating this student. Please try again.`)
                            }
                        }
                    })
                }
            });
        }
        else if (dial == "removeFile") {
            const dialogRef = this.dialog.open(DeleteFileApprovalComponent, {
                width: 'auto',
                data: file.FileName
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    file['EditStatus'] = { 'color': 'darkorange', 'text': 'Delete.' };
                    this.studentsService.removeOtherBehavioralDataFile(file).subscribe({
                        next: (result) => {
                            this.getFiles();
                        },
                        error: () => this.authService.alertError(`An error occured while deleting file "${file['FileName']}".`)
                    })
                }
            });
        }
    }

    getLevelOfClass(ua) {
        if (ua) {
            if (ua['TScore'] < 40) return 'highRisk';
            else if (ua['TScore'] < 46) return 'atRisk';
            else if (ua['TScore'] < 61) return 'typical';
            else return 'strength';
        }
        else return 'empty';
    }




    goBack() {
        this.location.back();
    }

    handleFileInput(files: FileList) {
        if (files.item(0)) {
            this.newFile['File'] = files.item(0);
            this.newFile['FileName'] = this.newFile['File'].name;
        }
    }
    searchFileName() {
        if (this.newFile['File']) return this.newFile['File'].name
        else return "No selected file (Max 10MB)"
    }






    addFile() {
        if (this.newFile['File']) {
            //  if (this.newFile['File'].size < 10485760) {
            this.messageType = false;
            this.messageText = '';
            this.progress = 0;

            const formData: FormData = new FormData();
            formData.append('file', this.newFile['File'], this.newFile['FileName']);

            const uploadReq = new HttpRequest('POST', `api/Files/UploadFile/${this.student.Id}`, formData, {
                reportProgress: true,
                responseType: 'text'
            });


            this.http.request(uploadReq).subscribe({
                next: (event) => {
                    if (event.type === HttpEventType.UploadProgress)
                        this.progress = Math.round(100 * event.loaded / event.total);
                    else if (event.type === HttpEventType.Response) {
                        this.messageType = true;
                        this.messageText = 'File uploaded successfully .';
                        this.getFiles();
                    }
                },
                error: () => {
                    this.messageType = false;
                    this.messageText = 'File failed to upload.';
                }
            });
        }
    }

    getFiles() {
        this.studentsService.getStudentOtherBehavioralDataFile(this.student.Id).subscribe({
            next: (files: StudentFileDTO[]) => {
                this.otherData = files;
            },
            error: () => this.otherData = []
        });
    }

    downloadFile(file) {
        this.studentsService.getBlobOtherBehavioralDataFile(this.student.Id, file.Id).subscribe({
            next: (data) => {
                switch (data.type) {
                    case HttpEventType.Response:
                        const downloadedFile = new Blob([data.body], { type: data.body.type });
                        const a = document.createElement('a');
                        a.setAttribute('style', 'display:none;');
                        document.body.appendChild(a);
                        a.download = file.FileName;
                        a.href = URL.createObjectURL(downloadedFile);
                        a.target = '_blank';
                        a.click();
                        document.body.removeChild(a);
                        break;
                }
            },
            error: () => {
                this.authService.alertError(`An error occured while requesting  "${file['FileName']}".`)
            }
        });
    }

    editFileTitle(file) {
        file['EditStatus'] = { 'color': 'darkorange', 'text': 'Edit file' };
        this.studentsService.editStudentOtherBehavioralDataFile(file.Id, file.FileName)
            .subscribe({
                next: (result) => {
                    file['EditStatus'] = { 'color': 'green', 'text': 'File successfully updated.' };
                },
                error: () => {
                    file['EditStatus'] = { 'color': 'red', 'text': 'File failed to update.' };
                }
            });
    }


}
