import { Component, Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { Grade } from '../Models/Grade';
import { Role } from '../Models/Role';
import { StaffMainDTO, StaffNameDTO, StaffDetailsDTO, StaffEditDTO, StaffActiveDTO } from '../Models/Staff';
import { StudentFullNameDTO, StudentIdFullNameDTO } from '../Models/Student';
import { AccountLicensesDTO } from '../Models/Account';

@Injectable()
export class StaffService {


    private staffAdded = '';

    constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

    isStaffAdded(): boolean {
        return !!this.staffAdded;
    }

    getStaffAdded(): string {
        return this.staffAdded;
    }

    setStaffAdded(staff: string): void {
        this.staffAdded = staff;
    }

    clearStaffAdded(): void {
        this.staffAdded = '';
    }

    getStaffRoles(): Observable<Role[]> {
        return this.http.get<Role[]>(`${this.baseUrl}api/Roles`).pipe(
            shareReplay()
        );
    }

    getStaffGrades(): Observable<Grade[]> {
        return this.http.get<Grade[]>(`${this.baseUrl}api/Grades`).pipe(
            shareReplay()
        );
    }

    getStaffMain(): Observable<StaffMainDTO[]> {
        return this.http.get<StaffMainDTO[]>(`${this.baseUrl}api/Staff/GetAllStaffMain`).pipe(
            shareReplay()
        );
    }

    getStaffNamesExceptRaters(): Observable<StaffNameDTO[]> {
        return this.http.get<StaffNameDTO[]>(`${this.baseUrl}api/Staff/GetStaffNamesExceptRaters`).pipe(
            shareReplay()
        );
    }

    getAllStaffNames(): Observable<StaffNameDTO[]> {
        return this.http.get<StaffNameDTO[]>(`${this.baseUrl}api/Staff/GetAllStaffNames`).pipe(
            shareReplay()
        );
    }

    getStaffDetailsById(id: number): Observable<StaffDetailsDTO> {
        return this.http.get<StaffDetailsDTO>(`${this.baseUrl}api/Staff/GetStaffDetailsById/${id}`).pipe(
            shareReplay()
        );
    }

    getStaffOwnDetails(): Observable<StaffDetailsDTO> {
        return this.http.get<StaffDetailsDTO>(`${this.baseUrl}api/Staff/GetStaffOwnDetails`).pipe(
            shareReplay()
        );
    }

    getStaffByIdEdit(id: number): Observable<StaffEditDTO> {
        return this.http.get<StaffEditDTO>(`${this.baseUrl}api/Staff/GetStaffByIdEdit/${id}`).pipe(
            shareReplay()
        );
    }

    getStaffActiveState(): Observable<StaffActiveDTO[]> {
        return this.http.get<StaffActiveDTO[]>(`${this.baseUrl}api/Staff/GetStaffActiveState`).pipe(
            shareReplay()
        );
    }

    importStaff(staff: any): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}api/Staff/Import`, staff).pipe(
            shareReplay()
        );
    }

    addStaff(staff: any): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}api/Staff/Add`, staff).pipe(
            shareReplay()
        );
    }

    updateStaff(staff: any): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}api/Staff/Update`, staff).pipe(
            shareReplay()
        );
    }

    deactivateStaff(id: number): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}api/Staff/Deactivate`, id).pipe(
            shareReplay()
        );
    }

    activateStaff(id: number): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}api/Staff/Activate`, id).pipe(
            shareReplay()
        );
    }

    sendSetupAccountEmail(id: number): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}api/Staff/SendSetupAccountEmail`, id).pipe(
            shareReplay()
        );
    }

    sendRegistrationEmails(schoolsIds: number[]): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}api/Staff/SendAllRegistrationEmails`, schoolsIds).pipe(
            shareReplay()
        );
    }

    getRaterOwnStudents(id: number): Observable<StudentFullNameDTO[]> {
        return this.http.get<StudentFullNameDTO[]>(`${this.baseUrl}api/Staff/GetRaterStudents/${id}`).pipe(
            shareReplay()
        );
    }

    getStaffAssessedStudents(staffId: number, periodId: number): Observable<StudentIdFullNameDTO[]> {
        return this.http.get<StudentIdFullNameDTO[]>(`${this.baseUrl}api/Students/GetAssessedStaffStudents/${staffId}/${periodId}`).pipe(
            shareReplay()
        );
    }

    getAdminEmail(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}api/Staff/GetSchoolAdminEmail`).pipe(
            shareReplay()
        );
    }

    getAccountLicenses(): Observable<AccountLicensesDTO> {
        return this.http.get<AccountLicensesDTO>(`${this.baseUrl}api/Staff/GetStaffAccountLicenses`).pipe(
            shareReplay()
        );
    }

    requestAccountLicenses(newLicenses: number): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}api/Staff/RequestAccountLicenses/${newLicenses}`).pipe(
            shareReplay()
        );
    }
}

