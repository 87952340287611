import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationBarComponent } from './Components/navigation-bar/navigation-bar.component';
import { SharedModule } from '@app/SharedModule/shared/shared.module';
import { RouterModule } from '@angular/router';
import { SchoolsService, StaffService, StudentsService, UaPeriodService, ReportsService, UsersService, AuthService, AssessmentService, AccountsService } from './Services';
import { ProgressMonitoringService } from './Services/progress-monitoring.service';


@NgModule({
  declarations: [
    NavigationBarComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule
  ],
  exports: [
    NavigationBarComponent
  ],
  providers: [
    SchoolsService,
    StaffService,
    StudentsService,
    UaPeriodService,
    ReportsService,
    UsersService,
    AuthService,
    AssessmentService,
    AccountsService,
    ProgressMonitoringService,
  ]
})
export class CoreModule { }
