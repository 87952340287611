import { Component, OnInit, Inject } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';

import 'rxjs';

import * as Papa from "papaparse";
import { StudentsService, AuthService, UsersService } from '@app/CoreModule/Services';
import { ImportRawDataCountersDTO, SurveyQuestionAnswer } from '@app/CoreModule/Models/Survey';
import { StudentImportRawData } from '@app/CoreModule/Models/Assessment';
import { UAPeriodDetailsDTO } from '@app/CoreModule/Models/UAPeriod';
import { SurveyService } from '@app/CoreModule/Services/survey.service';

@Component({
  selector: 'app-import-raw-data',
  templateUrl: './import-raw-data.component.html',
  styleUrls: ['./import-raw-data.component.css']
})

export class ImportRawDataComponent implements OnInit {
  fileInput: File;
  status: boolean = null;
  statusTextfield = "";
  studentsToImport: ImportRawData[] = [];
  allData: StudentImportRawData[] = [];

  hasMath = false;
  hasVerbal = false;
  hasComprehensive = false;

  importData: ImportRawDataCountersDTO;

  results: any;

  fileNames = { pdfName: "Import raw data instructions.pdf", csvName: `Raw data example.csv` };

  constructor(private authService: AuthService, private usersService: UsersService, private surveyService: SurveyService, public thisDialogRef: MatDialogRef<ImportRawDataComponent>, @Inject(MAT_DIALOG_DATA) public periodId: number) { }

  ngOnInit() {
    this.hasMath = false;
    this.hasVerbal = false;
    this.hasComprehensive = false;

    this.surveyService.getImportRawData(this.periodId).subscribe({
      next: (data: ImportRawDataCountersDTO) => {
        this.importData = data;
      },
      error: () => {
        this.authService.alertError(`An error occured while requesting import info.`);
      }
    })
  }

  downloadPDF() {
    this.usersService.getHelpFile(3).then(
      data => {
        if (data) {
          const downloadedFile = new Blob([data['body']], { type: data['body'].type });
          const a = document.createElement('a');
          a.setAttribute('style', 'display:none;');
          document.body.appendChild(a);
          a.download = this.fileNames.pdfName;
          a.href = URL.createObjectURL(downloadedFile);
          a.target = '_blank';
          a.click();
          document.body.removeChild(a);
        }

      },
      error => {
        this.authService.alertError(`An error occured while downloading '${this.fileNames.pdfName}' pdf file.`)
      }
    );
  }

  downloadCSV() {
    this.usersService.getHelpFile(7).then(
      data => {
        if (data) {
          const downloadedFile = new Blob([data['body']], { type: data['body'].type });
          const a = document.createElement('a');
          a.setAttribute('style', 'display:none;');
          document.body.appendChild(a);
          a.download = this.fileNames.csvName;
          a.href = URL.createObjectURL(downloadedFile);
          a.target = '_blank';
          a.click();
          document.body.removeChild(a);
        }

      },
      error => {
        this.authService.alertError(`An error occured while downloading '${this.fileNames.csvName}' csv file.`)
      }
    );
  }

  handleFileInput(files: FileList) {
    this.status = null;
    this.statusTextfield = "";

    this.fileInput = files.item(0);
    //console.log(this.fileInput )
    if (this.fileInput) {
      Papa.parse(this.fileInput, {
        encoding: 'cp1253', //iso-8859-7
        skipEmptyLines: true,
        complete: (results) => {
          this.checkCSVFile(results.data);
        }
      });
    }
  }
  searchFileName() {
    if (this.fileInput) return this.fileInput.name;
    else return "No file chosen.";
  }
  setErrors(status, statusTextfield) {
    this.status = status;
    this.statusTextfield = statusTextfield;
  }

  checkCSVFile(results) {
    try {
      var comprehensiveTitles = this.hasComprehensive ? ["Level"] : [];
      var titles = [
        "Student Id*", "Test*", ...comprehensiveTitles, ...[...Array(73).keys()].map(k => 'Q' + (k + 1))];

      var requiredTitles = ["Student Id*", "Test*"];

      var status = false;
      var statusTextfield: String = "";

      var fileSplit = this.fileInput.name.split('.');
      var fileType = fileSplit[fileSplit.length - 1];
      if (fileType != "csv") statusTextfield += "Error: Wrong file type, ." + fileType + " instead of .csv.\n"

      if (statusTextfield != "") return this.setErrors(status, statusTextfield);

      requiredTitles.forEach(title => {
        if (results[0].indexOf(title) == -1) statusTextfield += "Error: Title " + title + " is missing.\n";
      });

      if (statusTextfield != "") return this.setErrors(status, statusTextfield);

      var requiredTitlesIndex = [];
      requiredTitles.forEach(title => {
        requiredTitlesIndex.push(results[0].indexOf(title))
      });

      var counter = 0;
      var removedIndexes = [];
      var resLength = results.length;
      for (var i = 1; i < resLength; i++) {
        var row = results[i - counter];
        var emptyRow = true;
        for (var j = 1; j < results.length; j++) {
          var columnValue = row[j];
          if (columnValue != "" && columnValue != undefined) {
            emptyRow = false;
            break;
          }
        };
        if (emptyRow) {
          statusTextfield += "Warning: Row " + (i + 1) + " - Empty row removed.\n";
          removedIndexes.push(i - counter);
          results.splice(i - counter, 1);
          counter++;
        }
      };

      var testsMath = this.hasMath ? ['Survey Math'] : [];
      var testsVerbal = this.hasVerbal ? ['Survey Verbal'] : [];
      var testsComprehensive = this.hasComprehensive ? ['Vocabulary', 'Reading Comprehension', 'Spelling', 'Language Mechanics', 'Math Application', 'Math Computation'] : [];

      var tests = [...testsMath, ...testsVerbal, ...testsComprehensive];
      var levels = ['1', '2', '3', '4'];
      var answers = ['A', 'B', 'C', 'D'];

      for (var i = 1; i < results.length; i++) {
        requiredTitlesIndex.forEach(ind => {
          var column = results[i][ind];
          if (column == "") statusTextfield += "Error: Row " + (i + 1 + removedIndexes.filter(ind => { return ind < i + 1 }).length) + " - Empty field at " + results[0][ind] + " title.\n";
        });
      }

      if (statusTextfield != "" && statusTextfield.search("Error:") != -1) return this.setErrors(status, statusTextfield);
      else {
        this.results = results;
        this.studentsToImport = this.prepareArray(titles);

        var i = 1;
        this.studentsToImport.forEach((student) => {
          if (!tests.includes(student['Test'])) statusTextfield += "Error: Row " + (i + 1 + removedIndexes.filter(ind => { return ind < i + 1 }).length) + " - Invalid input at Test title.\n";

          student['Questions'].forEach(question => {
            if (question['AnswerId'] != null && !answers.includes(question['AnswerId'].trim())) statusTextfield += "Error: Row " + (i + 1 + removedIndexes.filter(ind => { return ind < i + 1 }).length) + ` - Invalid input at ${question['QuestionId']} title.\n`;
          });

          i++;
        })

        var uniqueStudentIds = this.studentsToImport.map(s => s.StudentId).filter((v, i, a) => a.indexOf(v) === i);

        uniqueStudentIds.forEach(studentId => {
          if (!this.importData.StudentIds.includes(studentId)) statusTextfield += `Error: There is no student with Student Id: ${studentId}.\n`;
        })

        if (statusTextfield != "" && statusTextfield.search("Error:") != -1) return this.setErrors(status, statusTextfield);
        else {
          uniqueStudentIds.forEach(studentId => {
            var studentRows = this.studentsToImport.filter(c => c.StudentId === studentId);
            var hasComprTests = [...studentRows.map(c => c.Test)].some(r => testsComprehensive.includes(r));

            if (hasComprTests && (results[0].filter((r: string) => r.toLowerCase().trim() == "level").length == 0)) statusTextfield += "Error: Title Level is missing.\n";

            if (statusTextfield != "" && statusTextfield.search("Error:") != -1) return this.setErrors(status, statusTextfield);

            if (hasComprTests) {
              var levelCount = [...studentRows].filter(c => c.Level.trim().length > 0);
              if (levelCount.length == 0 && hasComprTests) statusTextfield += `Error: No Level set for student id ${studentId}.\n`;
              else if (hasComprTests && !levels.includes(levelCount[0].Level)) statusTextfield += `Error: Invalid input at Level title for student id ${studentId}.\n`;
            }

            if (statusTextfield != "" && statusTextfield.search("Error:") != -1) return this.setErrors(status, statusTextfield);
            else if (hasComprTests) {
              this.allData.push(this.dataToScoringObject(studentRows, studentId, levelCount[0].Level))
            }
            else {
              this.allData.push(this.dataToScoringObject(studentRows, studentId))
            }
          })
        }

        if (statusTextfield != "" && statusTextfield.search("Error:") != -1) return this.setErrors(status, statusTextfield);
        else {
          this.allData.forEach((studentAnswers: StudentImportRawData) => {

            if (studentAnswers.TakeMath) {
              if (!this.hasMath) statusTextfield += `Error: You cannot apply tests for Survey Math, missing licenses.\n`;
              if (studentAnswers.MathQuestions.length != this.importData.MathQuestionsCount) statusTextfield += `Error: Plese check again, less questions were found for Survey Math test on student id ${studentAnswers.StudentId}.\n`;
            }
            if (studentAnswers.TakeVerbal) {
              if (!this.hasVerbal) statusTextfield += `Error: You cannot apply tests for Survey Verbal, missing licenses.\n`;
              if (studentAnswers.VerbalQuestions.length != this.importData.VerbalQuestionsCount) statusTextfield += `Error: Plese check again, less questions were found for Survey Verbal test on student id ${studentAnswers.StudentId}.\n`;
            }
            if (studentAnswers.TakeVocabulary || studentAnswers.TakeSpelling || studentAnswers.TakeLangMech || studentAnswers.TakeReadCompr || studentAnswers.TakeMathAppl || studentAnswers.TakeMathComp) {
              if (!this.hasComprehensive) statusTextfield += `Error: You cannot apply tests for Comprehensive, missing licenses.\n`;
            }
            if (studentAnswers.TakeVocabulary) {
              if (studentAnswers.VocabularyQuestions.length != this.importData.ComprQuestionsCount.filter(q => q.Form == this.importData.Form && q.Level == studentAnswers.Level && q.CategoryId == 1)[0].Counter) {
                statusTextfield += `Error: Plese check again, less questions were found for Vocabulary test on student id ${studentAnswers.StudentId}.\n`;
              }
            }
            if (studentAnswers.TakeSpelling) {
              if (studentAnswers.SpellingQuestions.length != this.importData.ComprQuestionsCount.filter(q => q.Form == this.importData.Form && q.Level == studentAnswers.Level && q.CategoryId == 2)[0].Counter) {
                statusTextfield += `Error: Plese check again, less questions were found for Spelling test on student id ${studentAnswers.StudentId}.\n`;
              }
            }
            if (studentAnswers.TakeLangMech) {
              if (studentAnswers.LanguageMechanicsQuestions.length != this.importData.ComprQuestionsCount.filter(q => q.Form == this.importData.Form && q.Level == studentAnswers.Level && q.CategoryId == 3)[0].Counter) {
                statusTextfield += `Error: Plese check again, less questions were found for Language Mechanics test on student id ${studentAnswers.StudentId}.\n`;
              }
            }
            if (studentAnswers.TakeReadCompr) {
              if (studentAnswers.ReadingComprehensionQuestions.length != this.importData.ComprQuestionsCount.filter(q => q.Form == this.importData.Form && q.Level == studentAnswers.Level && q.CategoryId == 4)[0].Counter) {
                statusTextfield += `Error: Plese check again, less questions were found for Reading Comprehension test on student id ${studentAnswers.StudentId}.\n`;
              }
            }
            if (studentAnswers.TakeMathComp) {
              if (studentAnswers.MathComputationQuestions.length != this.importData.ComprQuestionsCount.filter(q => q.Form == this.importData.Form && q.Level == studentAnswers.Level && q.CategoryId == 5)[0].Counter) {
                statusTextfield += `Error: Plese check again, less questions were found for Math Computation test on student id ${studentAnswers.StudentId}.\n`;
              }
            }
            if (studentAnswers.TakeMathAppl) {
              if (studentAnswers.MathApplicationQuestions.length != this.importData.ComprQuestionsCount.filter(q => q.Form == this.importData.Form && q.Level == studentAnswers.Level && q.CategoryId == 6)[0].Counter) {
                statusTextfield += `Error: Plese check again, less questions were found for Math Application test on student id ${studentAnswers.StudentId}.\n`;
              }
            }
          })
        }

        if (statusTextfield != "" && statusTextfield.search("Error:") != -1) return this.setErrors(status, statusTextfield);
        else {
          status = true;
          if (statusTextfield != "") statusTextfield += "\n";
          statusTextfield += "File CSV has correct type.\n\n"
            + `Found results for ${this.allData.length} student${this.allData.length == 1 ? '' : 's'}.\n\n`
            + `Click \'Import raw data\' below to proceed with the procedure.`;

          return this.setErrors(status, statusTextfield);

        }
      }
    }
    catch (er) {
      return this.setErrors(status, "An error occured with your csv file, please check again that your data is correct according to the provided instructions.");
    }
  }
  //var tests = [, 'Survey Verbal', 'Vocabulary', 'Reading Comprehension', 'Spelling', 'Language Mechanics', 'Math Application', 'Math Computation'];

  dataToScoringObject(studentRows: ImportRawData[], studentId: string, level?: string) {
    var data: StudentImportRawData = new StudentImportRawData(studentId);

    data.Level = level;

    studentRows.forEach(row => {
      if (row.Test == 'Survey Math') {
        data.TakeMath = true;
        data.MathQuestions = this.sliceQuestions(row.Questions, this.importData.MathQuestionsCount);
      }
      else if (row.Test == 'Survey Verbal') {
        data.TakeVerbal = true;
        data.VerbalQuestions = this.sliceQuestions(row.Questions, this.importData.VerbalQuestionsCount);
      }
      else if (row.Test == 'Vocabulary') {
        data.TakeVocabulary = true;
        let counter = this.importData.ComprQuestionsCount.filter(q => q.Form == this.importData.Form && q.Level == data.Level && q.CategoryId == 1)[0].Counter;
        data.VocabularyQuestions = this.sliceQuestions(row.Questions, counter);
      }
      else if (row.Test == 'Spelling') {
        data.TakeSpelling = true;
        let counter = this.importData.ComprQuestionsCount.filter(q => q.Form == this.importData.Form && q.Level == data.Level && q.CategoryId == 2)[0].Counter;
        data.SpellingQuestions = this.sliceQuestions(row.Questions, counter);
      }
      else if (row.Test == 'Language Mechanics') {
        data.TakeLangMech = true;
        let counter = this.importData.ComprQuestionsCount.filter(q => q.Form == this.importData.Form && q.Level == data.Level && q.CategoryId == 3)[0].Counter;
        data.LanguageMechanicsQuestions = this.sliceQuestions(row.Questions, counter);
      }
      else if (row.Test == 'Reading Comprehension') {
        data.TakeReadCompr = true;
        let counter = this.importData.ComprQuestionsCount.filter(q => q.Form == this.importData.Form && q.Level == data.Level && q.CategoryId == 4)[0].Counter;
        data.ReadingComprehensionQuestions = this.sliceQuestions(row.Questions, counter);
      }
      else if (row.Test == 'Math Computation') {
        data.TakeMathComp = true;
        let counter = this.importData.ComprQuestionsCount.filter(q => q.Form == this.importData.Form && q.Level == data.Level && q.CategoryId == 5)[0].Counter;
        data.MathComputationQuestions = this.sliceQuestions(row.Questions, counter);
      }
      else if (row.Test == 'Math Application') {
        data.TakeMathAppl = true;
        let counter = this.importData.ComprQuestionsCount.filter(q => q.Form == this.importData.Form && q.Level == data.Level && q.CategoryId == 6)[0].Counter;
        data.MathApplicationQuestions = this.sliceQuestions(row.Questions, counter);
      }
    })

    return data;
  }

  sliceQuestions(questions: SurveyQuestionAnswer[], questionsLength) {
    var questionIds = Array.from({ length: questionsLength }, (_, i) => 'Q' + (i + 1));
    return questions.filter(q => questionIds.includes(q.QuestionId));
  }

  prepareArray(titles) {
    for (let i = 0; i < this.results.length; i++) {
      for (let j = 0; j < this.results[0].length; j++) {
        this.results[i][j] = this.results[i][j].trim();
      }
    }

    var studentAttributes: string[] = [];
    this.results[0].forEach(title => {
      if (title == "Student Id*") studentAttributes.push("StudentId");
      else if (title == "Test*") studentAttributes.push("Test");
      else studentAttributes.push(title);
    });

    var allStudents = [];
    var studentRow;
    var student: ImportRawData;
    for (var i = 1; i < this.results.length; i++) {
      studentRow = this.results[i];
      student = new ImportRawData();
      for (var j = 0; j < studentAttributes.length; j++) {
        if (studentAttributes[j].toLowerCase().includes('q')) {
          student.Questions.push({
            QuestionId: this.matchArrayTitle(titles, studentAttributes[j]),
            AnswerId: (studentRow[j] && studentRow[j].trim().length > 0 ?
              studentRow[j].toUpperCase().trim() : null)
          });
        }
        else student[this.matchArrayTitle(titles, studentAttributes[j])] = studentRow[j].trim();
      }
      allStudents.push(student);
    }

    return allStudents;
  }

  matchArrayTitle(titles: string[], givenTitle: string) {
    if (givenTitle == "StudentId" || givenTitle == "Test") return givenTitle;
    else return titles.filter(t => t.toLowerCase().trim() === givenTitle.toLowerCase().trim())[0];
  }


  onCloseConfirm() {
    this.thisDialogRef.close(this.allData);

  }

  onCloseCancel() {
    this.thisDialogRef.close(false);
  }


}

class ImportRawData {
  StudentId: string;
  Level: string;
  Test: string;
  Questions: SurveyQuestionAnswer[];

  constructor() {
    this.Questions = [];
  }
}