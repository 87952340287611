// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
    width: 100%;
    border-bottom: 3px solid rgba(2, 15, 152, 0.83);
    text-transform: uppercase;
    text-align: center;
}

.title h2 {
    margin-bottom: 0;
    font-weight: bold;
}

mat-dialog-content {
    text-align: center;
}

.file_div {
    height: 40px;
    display: inline-block;
    margin: auto;
}

input[type="file"] {
    display: none;
}

label.btn-outline-primary {
    float: left;
    font-weight: bold;
    cursor: pointer;
}

.line {
    float: left;
    width: 100%;
    margin: 12px 0;
}

textarea {
    width: 80%;
    min-height: 130px;
}

button {
    margin: 5px 5px 20px;
}

a {
    text-decoration: underline;
}

.file-none::after {
    content: "N/A";
    color: grey;
}

.file-fail::after {
    content: "Failed";
    color: red;
}

.file-success::after {
    content: "Success";
    color: green;
}`, "",{"version":3,"sources":["webpack://./src/app/FeatureModules/admin/ua-periods/Components/import-raw-data/import-raw-data.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,+CAA+C;IAC/C,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,qBAAqB;IACrB,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,cAAc;AAClB;;AAEA;IACI,UAAU;IACV,iBAAiB;AACrB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,cAAc;IACd,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,YAAY;AAChB","sourcesContent":[".title {\r\n    width: 100%;\r\n    border-bottom: 3px solid rgba(2, 15, 152, 0.83);\r\n    text-transform: uppercase;\r\n    text-align: center;\r\n}\r\n\r\n.title h2 {\r\n    margin-bottom: 0;\r\n    font-weight: bold;\r\n}\r\n\r\nmat-dialog-content {\r\n    text-align: center;\r\n}\r\n\r\n.file_div {\r\n    height: 40px;\r\n    display: inline-block;\r\n    margin: auto;\r\n}\r\n\r\ninput[type=\"file\"] {\r\n    display: none;\r\n}\r\n\r\nlabel.btn-outline-primary {\r\n    float: left;\r\n    font-weight: bold;\r\n    cursor: pointer;\r\n}\r\n\r\n.line {\r\n    float: left;\r\n    width: 100%;\r\n    margin: 12px 0;\r\n}\r\n\r\ntextarea {\r\n    width: 80%;\r\n    min-height: 130px;\r\n}\r\n\r\nbutton {\r\n    margin: 5px 5px 20px;\r\n}\r\n\r\na {\r\n    text-decoration: underline;\r\n}\r\n\r\n.file-none::after {\r\n    content: \"N/A\";\r\n    color: grey;\r\n}\r\n\r\n.file-fail::after {\r\n    content: \"Failed\";\r\n    color: red;\r\n}\r\n\r\n.file-success::after {\r\n    content: \"Success\";\r\n    color: green;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
