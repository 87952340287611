import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { Observable } from 'rxjs';

import { tap } from 'rxjs/operators'


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const idToken = localStorage.getItem("token");
        if (idToken) {
            var cloned = null;
            if (req.url.indexOf('UploadFile') != -1 || req.url.indexOf('UploadSchoolImage') != -1 || req.url.includes('UploadFlexItemImages')) {
                cloned = req.clone({
                    headers: req.headers.set("Authorization", "Bearer " + idToken)
                });
            }
            else {
                cloned = req.clone({
                    headers: req.headers.set("Authorization", "Bearer " + idToken)
                        .set("Content-Type", "application/json")
                });
            }


            return next.handle(cloned).pipe(tap((event: HttpEvent<any>) => {

                if (event instanceof HttpResponse) {
                    if (event.headers.get("Set-Authorization")) {
                        if (idToken != event.headers.get("Set-Authorization")) {
                            localStorage.setItem('token', event.headers.get("Set-Authorization"))
                        };
                    }
                }

            }))

        }
        else {
            return next.handle(req);
        }
    }
}