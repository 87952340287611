// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div {
    padding: 0.5rem 0;
}

img {
    margin: 0 auto;
    max-width: 200px;
    width: 100%;
    display: block;
}

p {
    font-weight: bold;
    font-size: 14px;
    max-width: 256px;
    margin: 0 auto 0px;
    color: #0033ee;
    line-height: 19px;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/SharedModule/shared/Components/logo/logo.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,WAAW;IACX,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,cAAc;IACd,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":["div {\r\n    padding: 0.5rem 0;\r\n}\r\n\r\nimg {\r\n    margin: 0 auto;\r\n    max-width: 200px;\r\n    width: 100%;\r\n    display: block;\r\n}\r\n\r\np {\r\n    font-weight: bold;\r\n    font-size: 14px;\r\n    max-width: 256px;\r\n    margin: 0 auto 0px;\r\n    color: #0033ee;\r\n    line-height: 19px;\r\n    text-align: center;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
