import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map, shareReplay } from 'rxjs/operators';
//import * as moment from "moment";
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomRoles } from '../Models/CustomRoles';

//import { User } from '../Models/user';

class User {
    Email: string;
    Password: string;
}

@Injectable()
export class AuthService {

    constructor(private snackBar: MatSnackBar, private http: HttpClient, public router: Router, @Inject('BASE_URL') public baseUrl: string) { }

    login(user: User) {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        //        return this.http.post(this.baseUrl + 'api/Users/RequestResetPassword', data, { headers: headers, responseType: 'text' }).pipe(map(result => {

        return this.http.post('api/Users/Login', user, { headers: headers, responseType: 'text' })
            .pipe(map(response => {
                let token = JSON.parse(response)['Token'];
                localStorage.setItem('token', token);
            }));
    }

    logout() {
        localStorage.removeItem("token");
        this.router.navigate(['/login']);
    }

    performAccountAdminLogin(token) {
        localStorage.removeItem("token");
        localStorage.setItem('token', token);
        setTimeout(() => this.router.navigate(['/schools']), 1000);
    }

    decodeToken() {
        const helper = new JwtHelperService();
        const token = localStorage.getItem('token');
        return helper.decodeToken(token);
    }

    getRole() {
        let user = this.decodeToken();
        if (user) return user['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
        else return null;
    }

    isRaterOnly() {
        return this.getRole() == CustomRoles.RaterOnly;
    }

    isAdmin() {
        let user = this.decodeToken();
        return +user['IsAdmin'] === 1;
    }

    isSetupRequired() {
        let user = this.decodeToken();
        return +user['SetupRequired'];
    }

    hasAgreedOnTerms() {
        let user = this.decodeToken();
        return +user['AgreedOnTerms'];
    }

    isActive() {
        let user = this.decodeToken();
        return +user['Active'];
    }

    getUser() {
        let user = this.decodeToken();
        return user;
    }

    isLoggedIn() {
        const idToken = localStorage.getItem("token");
        if (idToken) return true;
        else return false;
    }

    isLoggedOut() {
        return !this.isLoggedIn();
    }

    /*
    getExpiration() {
        const expiration = localStorage.getItem("token");
        const expiresAt = JSON.parse(expiration);
        console.log
        return moment(expiresAt);
    }
    */

    isTokenExpired() {
        const jwtHelper = new JwtHelperService();
        const token = localStorage.getItem("token");
        return jwtHelper.isTokenExpired(token);
    }

    alertError(text) {
        this.snackBar.open(text, "Χ", {
            duration: 8000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['error-snackbar']
        });
    }

    alertSuccess(text) {
        this.snackBar.open(text, "Χ", {
            duration: 8000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['success-snackbar']
        });
    }

    alertWarning(text) {
        this.snackBar.open(text, "Χ", {
            duration: 8000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['warning-snackbar']
        });
    }


}
