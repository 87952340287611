import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, Input, SimpleChanges, OnChanges } from '@angular/core';

import { Chart } from 'chart.js';
import { ChartOptions, ChartType } from 'chart.js';

@Component({
  selector: 'app-pyramid',
  templateUrl: './pyramid.component.html',
  styleUrl: './pyramid.component.css'
})
export class PyramidComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() highRisk_scores: any; // Define the input property
  @Input() atRisk_scores: number[];
  @Input() typical_scores: number[];
  @Input() strength_scores: number[];

  @ViewChild('lineChart') private chartRef!: ElementRef;
  chart: any;

  noResults: boolean;

  constructor() { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges) {
    // If any input property changes, update the chart data
    if (this.chart && (changes.highRisk_scores || changes.atRisk_scores || changes.typical_scores || changes.strength_scores)) {
      this.updateChart();
    }
  }

  ngAfterViewInit() {
    this.initializeChart();
  }

  initializeChart() {
    if (this.chart) this.chart.destroy();
    this.chart = new Chart(this.chartRef.nativeElement, {
      type: 'bar',
      data: {
        labels: ['', '', '', '', ''],
        datasets: [
          {
            label: 'High Risk',
            data: this.highRisk_scores,
            backgroundColor: 'rgb(255, 51, 0)',
            stack: 'Stack 0',
            //xAxisID: 'x-axis-4'
          },
          {
            label: 'At Risk',
            data: this.atRisk_scores,
            backgroundColor: 'rgb(255, 255, 0)',
            stack: 'Stack 0',
            //xAxisID: 'x-axis-3'
          },
          {
            label: 'Typical',
            data: this.typical_scores,
            backgroundColor: 'rgb(169, 208, 142)',
            stack: 'Stack 0',
            //xAxisID: 'x-axis-2'
          },
          {
            label: 'Strength',
            data: this.strength_scores,
            backgroundColor: 'rgb(112, 173, 71)',
            stack: 'Stack 0',
            //xAxisID: 'x-axis-1'
          }
        ]
      },
      options: {
        // animation: null,
        hover: { mode: null },
        tooltips: {
          enabled: true,
          mode: 'index',
          intersect: false,
          callbacks: {
            label: function (tooltipItem, data) {
              return `${tooltipItem.yLabel}%`;
              // return ` ${data.datasets[tooltipItem.datasetIndex].label}: ${tooltipItem.yLabel}%`;
            }
          }
        },
        legend: {
          display: false
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true
          },
          yAxes: [{
            gridLines: {
              display: true,
              drawBorder: true,
              drawOnChartArea: true,
              drawTicks: false,
            },
            ticks: {
              display: false,
              beginAtZero: true,
              min: 0,
              max: 100,
              stepSize: 10,
              fontColor: '#000',
              reverse: true
            },
          }],
          xAxes: [{
            gridLines: {
              display: true,
              drawBorder: true,
              drawOnChartArea: true,
              drawTicks: false,
            },
            ticks: {
              display: false,
            },
            categoryPercentage: 1,
            barPercentage: 1,
          },
          {
            id: 'x-axis-1',
            type: 'category',
            offset: true,
            display: false,
            categoryPercentage: 1,
            barPercentage: 0.8,
          },
          {
            id: 'x-axis-2',
            type: 'category',
            offset: true,
            display: false,
            categoryPercentage: 1,
            barPercentage: 0.7,
          },
          {
            id: 'x-axis-3',
            type: 'category',
            offset: true,
            display: false,
            categoryPercentage: 1,
            barPercentage: 0.6,
          },
          {
            id: 'x-axis-4',
            type: 'category',
            offset: true,
            display: false,
            categoryPercentage: 1,
            barPercentage: 0.5,
          }]
        },
      },
    });
  }

  private updateChart(): void {
    var isEqual1 = (JSON.stringify(this.chart.data.datasets[0].data.sort()) === JSON.stringify(this.highRisk_scores.sort()));
    var isEqual2 = (JSON.stringify(this.chart.data.datasets[1].data.sort()) === JSON.stringify(this.atRisk_scores.sort()));
    var isEqual3 = (JSON.stringify(this.chart.data.datasets[2].data.sort()) === JSON.stringify(this.typical_scores.sort()));
    var isEqual4 = (JSON.stringify(this.chart.data.datasets[3].data.sort()) === JSON.stringify(this.strength_scores.sort()));

    if (!isEqual1 || !isEqual2 || !isEqual3 || !isEqual4) {
      this.noResults = (this.highRisk_scores[0] + this.atRisk_scores[0] + this.typical_scores[0] + this.strength_scores[0]) == 0;

      this.chart.data.datasets[0].data = this.highRisk_scores;
      this.chart.data.datasets[1].data = this.atRisk_scores;
      this.chart.data.datasets[2].data = this.typical_scores;
      this.chart.data.datasets[3].data = this.strength_scores;

      this.chart.update();
    }
  }
}