import { Component, OnInit, Inject } from "@angular/core";

import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { AccountLicensesDTO } from "@app/CoreModule/Models/Account";

@Component({
    selector: 'request-licenses-modal',
    templateUrl: './request-licenses-modal.html',
    styleUrls: ['./request-licenses-modal.css']
})
export class RequestLicensesModal implements OnInit {
    account: AccountLicensesDTO = {} as AccountLicensesDTO;
    accountNew: number = 0;

    constructor(@Inject(MAT_DIALOG_DATA) public data: AccountLicensesDTO, public thisDialogRef: MatDialogRef<RequestLicensesModal>, @Inject(MAT_DIALOG_DATA) public accountId: number) {
        this.account = data
    }

    ngOnInit() {
        var elements = document.getElementsByTagName("input");
        elements[0].addEventListener("input", function (e) {
            (<HTMLInputElement>e.target).value = parseInt((<HTMLInputElement>e.target).value).toString();
            if ((<HTMLInputElement>e.target).value == '') (<HTMLInputElement>e.target).value = '0';
        });
    }

    getAbsNumber(num) {
        return Math.abs(num)
    }

    onCloseConfirm() {
        this.thisDialogRef.close(this.accountNew);
    }

    onCloseCancel() {
        this.thisDialogRef.close(false);
    }
}

