import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient, HttpHeaders } from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AuthInterceptor } from './auth.interceptor';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/el';
registerLocaleData(localeFr, 'el');

import { Globals } from '@app/globals';
import { SharedModule } from './SharedModule/shared/shared.module';
import { SelectivePreloadingStrategy } from './app-selective-preloading';
import { CoreModule } from './CoreModule/core.module';
import { AuthGuard } from './SharedModule/shared/Guards/auth.guard';
import { EnsureActionModalComponent } from './CustomComponents/ensure-action-modal/ensure-action-modal.component';
import { MathjaxComponent } from './CustomComponents/mathjax/mathjax.component';
import { ImportRawDataComponent } from './FeatureModules/admin/ua-periods/Components/import-raw-data/import-raw-data.component';
import { AssessmentModule } from './FeatureModules/assessment/assessment.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';


@NgModule({
    declarations: [
        AppComponent,
        EnsureActionModalComponent,
        //MathjaxComponent,
        ImportRawDataComponent,
        //AssessmentModule
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        FormsModule,
        SharedModule,
        CoreModule,
        ReactiveFormsModule,
        AppRoutingModule,


    ],
    providers: [
        HttpClientModule,
        HttpClient,
        AuthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        Globals,

        SelectivePreloadingStrategy,
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { subscriptSizing: 'dynamic' } },
        { provide: LocationStrategy, useClass: HashLocationStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
