// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#page-content{
    height: 100%;
}

::ng-deep .mat-simple-snackbar-action{
    color: #500c0c;
}
`, "",{"version":3,"sources":["webpack://./src/app/app.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB","sourcesContent":["#page-content{\r\n    height: 100%;\r\n}\r\n\r\n::ng-deep .mat-simple-snackbar-action{\r\n    color: #500c0c;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
