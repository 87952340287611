import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { StudentFormDataRaterDTO, StudentFormDataParentDTO, ResultRaterDTO, StudentUAFullResultsDTO, StudentUAQuestionAnswersDTO, ParentLetterDTO } from '../Models/Assessment';
import { GetParentLinkDTO } from '../Models/UAPeriod';
import { map, shareReplay } from 'rxjs/operators';


@Injectable()
export class AssessmentService {
    notAssessedStudents = [];

    constructor(private http: HttpClient, public router: Router, @Inject('BASE_URL') public baseUrl: string) { }


    getRaterAssessmentData(studentId: number) {
        return this.http.get<StudentFormDataRaterDTO>(this.baseUrl + `api/Assessment/GetRaterAssessmentData/${studentId}`)
            .pipe(
                map(result => result),
                shareReplay()
            );
    }

    getParentAssessmentData(studentId: number, parentId: number, token: string) {
        return this.http.get<StudentFormDataParentDTO>(this.baseUrl + `api/Assessment/GetParentAssessmentData/${studentId}/${parentId}/${token}`)
            .pipe(
                map(result => result),
                shareReplay()
            );
    }

    submitRaterAssessment(assessment: any) {
        return this.http.post(this.baseUrl + 'api/Assessment/SubmitRaterAssessment', assessment)
            .pipe(shareReplay());
    }

    submitParentAssessment(assessment: any) {
        return this.http.post(this.baseUrl + 'api/Assessment/SubmitParentAssessment', assessment)
            .pipe(shareReplay());
    }

    submitAndNextRaterAssessment(assessment: any) {
        return this.http.post<StudentFormDataRaterDTO>(this.baseUrl + 'api/Assessment/SubmitAndNextRaterAssessment', assessment)
            .pipe(shareReplay());
    }

    saveRaterAssessment(assessment: any) {
        return this.http.post(this.baseUrl + 'api/Assessment/SaveRaterAssessment', assessment)
            .pipe(shareReplay());
    }

    getAssessmentStatus(studentId: number) {
        return this.http.get<boolean>(this.baseUrl + `api/Assessment/GetAssessmentStatus/${studentId}`)
            .pipe(
                map(result => result),
                shareReplay()
            );
    }

    getAssessmentResults(periodId: number) {
        return this.http.get<ResultRaterDTO[]>(this.baseUrl + `api/Assessment/GetAssessmentResults/${periodId}`)
            .pipe(
                map(result => result),
                shareReplay()
            );
    }

    sendCheckRosterEmails(periodId: number, schoolIds: number[], gradeIds: number[]) {
        return this.http.post(this.baseUrl + 'api/UAPeriod/CheckRoster', { periodId, schoolIds, gradeIds })
            .pipe(shareReplay());
    }

    sendActivePeriodRaterEmails(periodId: number, schoolIds: number[], gradeIds: number[]) {
        return this.http.post(this.baseUrl + 'api/UAPeriod/ActivePeriodRater', { periodId, schoolIds, gradeIds })
            .pipe(shareReplay());
    }

    GetParentLinks(periodId: number, schoolIds: number[], gradeIds: number[]) {
        var schools = schoolIds.map(id => `schoolIds=${id}`);
        var grades = gradeIds.map(id => `gradeIds=${id}`);
        var combined = [...schools, ...grades].join("&");
        return this.http.get<GetParentLinkDTO[]>(this.baseUrl + `api/UAPeriod/GetParentLinks/${periodId}?${combined}`)
            .pipe(
                map(result => result),
                shareReplay()
            );
    }

    sendActivePeriodGuardianEmails(periodId: number, schoolIds: number[], gradeIds: number[]) {
        return this.http.post(this.baseUrl + 'api/UAPeriod/ActivePeriodGuardian', { periodId, schoolIds, gradeIds })
            .pipe(shareReplay());
    }

    getStudentUAResults(studentId: number) {
        return this.http.get<StudentUAFullResultsDTO[]>(this.baseUrl + `api/Assessment/GetStudentUAResults/${studentId}`)
            .pipe(
                map(result => result),
                shareReplay()
            );
    }

    getStudentUAAnswers(studentId: number, periodId: number) {
        return this.http.get<StudentUAQuestionAnswersDTO>(this.baseUrl + `api/Assessment/GetStudentUAAnswers/${studentId}/${periodId}`)
            .pipe(
                map(result => result),
                shareReplay()
            );
    }

    getParentLetterData(studentsIds: number[], periodId: number) {
        periodId = parseInt(periodId as any); // ensure periodId is a number
        return this.http.post<ParentLetterDTO[]>(this.baseUrl + 'api/Assessment/GetParentLetterData', { studentsIds, periodId })
            .pipe(shareReplay());
    }

    getCSVResourcesData(data: any) {
        return this.http.post<string[][]>(this.baseUrl + 'api/Assessment/GetCSVResourcesData', data)
            .pipe(shareReplay());

    }

    saveCategoryComment(studentId: number, periodId: number, categoryId: number, comment: string) {
        return this.http.post(this.baseUrl + 'api/Assessment/SaveCategoryComment', { StudentId: studentId, PeriodId: periodId, CategoryId: categoryId, Comment: comment })

            .pipe(shareReplay());

    }

    saveGeneralComment(studentId: number, periodId: number, comment: string) {
        return this.http.post(this.baseUrl + 'api/Assessment/SaveGeneralComment', { StudentId: studentId, PeriodId: periodId, CategoryId: 0, Comment: comment })

            .pipe(shareReplay());

    }

}
