// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
    width: 100%;
    border-bottom: 3px solid var(--borderColor);
    text-transform: uppercase;
    text-align: center;
}

.title h2 {
    margin-bottom: 0;
    font-weight: bold;
}

mat-dialog-content {
    text-align: center;
    font-size: 16px;
    line-height: 18px;
    overflow: hidden;
}

br {
    display: block;
    margin: 8px 0;
}

button {
    margin: 15px 5px 20px;
}

b {
    white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/app/SharedModule/shared/Components/students/delete-file-approval/delete-file-approval.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,2CAA2C;IAC3C,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,aAAa;AACjB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".title {\r\n    width: 100%;\r\n    border-bottom: 3px solid var(--borderColor);\r\n    text-transform: uppercase;\r\n    text-align: center;\r\n}\r\n\r\n.title h2 {\r\n    margin-bottom: 0;\r\n    font-weight: bold;\r\n}\r\n\r\nmat-dialog-content {\r\n    text-align: center;\r\n    font-size: 16px;\r\n    line-height: 18px;\r\n    overflow: hidden;\r\n}\r\n\r\nbr {\r\n    display: block;\r\n    margin: 8px 0;\r\n}\r\n\r\nbutton {\r\n    margin: 15px 5px 20px;\r\n}\r\n\r\nb {\r\n    white-space: nowrap;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
