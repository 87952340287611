import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as Pipes from './Pipes/index';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { MultipleSelectComponent } from './Components/multiple-select/multiple-select.component';

import { MatInputModule } from '@angular/material/input';
import { MatSliderModule } from '@angular/material/slider';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatOptionModule } from '@angular/material/core'; // Note: MatOptionModule is under core
import { MatTabsModule } from '@angular/material/tabs';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';

import { EnsureActionModalComponent } from './Components/ensure-action-modal/ensure-action-modal.component';
import { AuthGuard } from './Guards/auth.guard';
import { RoleGuard } from './Guards/role.guard';
import { SuperadminGuard } from './Guards/superadmin.guard';
import { DetailsStaffComponent } from './Components/staff/details-staff/details-staff.component';
import { ActivateMemberStaffComponent } from './Components/staff/activate-member-staff/activate-member-staff.component';
import { ChangePasswordComponent } from './Components/staff/change-password/change-password.component';
import { DeactivateMemberStaffComponent } from './Components/staff/deactivate-member-staff/deactivate-member-staff.component';
import { LoginLinkStaffComponent } from './Components/staff/login-link-staff/login-link-staff.component';
import { ActivateStudentComponent } from './Components/students/activate-student/activate-student.component';
import { DeactivateStudentComponent } from './Components/students/deactivate-student/deactivate-student.component';
import { DeleteFileApprovalComponent } from './Components/students/delete-file-approval/delete-file-approval.component';
import { ShowAssessmentComponent } from './Components/students/show-assessment/show-assessment.component';
import { RouterModule } from '@angular/router';
import { DetailsStudentsComponent } from './Components/students/details-students/details-students.component';
import { PeriodsLineChartComponent } from './Components/students/periods-line-chart/periods-line-chart.component';
import { CompleteFormStudentComponent } from './Components/students/complete-form-student/complete-form-student.component';

import { LogoComponent } from './Components/logo/logo.component';
import { RequestLicensesModal } from './Components/staff/request-licenses/request-licenses-modal';
import { SingleSelectComponent } from '@app/CustomComponents/single-select/single-select.component';
import { PyramidComponent } from '@app/CustomComponents/pyramid/pyramid.component';

@NgModule({
  declarations: [
    Pipes.OrderByPipe,
    Pipes.SearchForPipe,
    Pipes.SplitByPipe,
    Pipes.SearchForSpecificPipe,
    Pipes.SearchColumnsPipe,
    Pipes.RatersSearchStudentPipe,
    Pipes.FilterStaffByGradePipe,

    SingleSelectComponent,
    MultipleSelectComponent,
    EnsureActionModalComponent,

    DetailsStudentsComponent,
    ShowAssessmentComponent,
    DeactivateStudentComponent,
    ActivateStudentComponent,
    DeleteFileApprovalComponent,

    DetailsStaffComponent,
    LoginLinkStaffComponent,
    DeactivateMemberStaffComponent,
    ActivateMemberStaffComponent,
    ChangePasswordComponent,
    CompleteFormStudentComponent,
    PeriodsLineChartComponent,
    LogoComponent,
    RequestLicensesModal,
    PyramidComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    MatInputModule,
    MatSliderModule,
    MatRadioModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTooltipModule,
    MatOptionModule,
    MatTabsModule,
    MatSnackBarModule,
    MatListModule,
    MatIconModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    MatInputModule,
    MatSliderModule,
    MatRadioModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTooltipModule,
    MatOptionModule,
    MatTabsModule,
    MatSnackBarModule,
    MatListModule,
    MatIconModule,

    NgxPaginationModule,

    Pipes.OrderByPipe,
    Pipes.SearchForPipe,
    Pipes.SplitByPipe,
    Pipes.SearchForSpecificPipe,
    Pipes.SearchColumnsPipe,
    Pipes.RatersSearchStudentPipe,
    Pipes.FilterStaffByGradePipe,

    SingleSelectComponent,
    MultipleSelectComponent,
    EnsureActionModalComponent,
    LogoComponent,
    PyramidComponent
  ],
  providers: [
    AuthGuard,
    RoleGuard,
    SuperadminGuard,

    Pipes.SearchForSpecificPipe,
  ],
})
export class SharedModule { }
