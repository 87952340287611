import { StudentIdFullNameDTO } from './Student';
import { ComprQuestionsCount, SurveyQuestionAnswer } from './Survey';
import { UaPeriodIdNameDTO, UAPeriodDTO, UAPeriodScoringDTO } from './UAPeriod';

export interface StudentFormDataRaterDTO {
    Period: UaPeriodIdNameDTO;
    Student: StudentIdFullNameDTO;
    Questions: QuestionTextDTO[];
    Answers: QuestionAnswer[];
    Comment: string;
}

export interface QuestionAnswer {
    QuestionId: string;
    Answer: number;
    TimeStamp: string;
}

export interface QuestionTextDTO {
    QuestionId: string;
    QuestionText: string;
}

export interface StudentFormDataParentDTO {
    Period: UAPeriodDTO;
    Student: StudentIdFullNameDTO;
    Questions: QuestionTextDTO[];
}

export interface ResultRaterDTO {
    StudentId: number;
    BasicReading: number;
    ReadingComprehension: number;
    Writing: number;
    ExecutiveSkills: number;
}

export interface StudentUAFullResultsDTO {
    PeriodId: number;
    PeriodName: string;
    PeriodStart: string;
    PeriodEnd: string;
    IsAssessed: boolean;
    TscoreBasicReading: number;
    TscoreReadingComprehension: number;
    TscoreWriting: number;
    TscoreExecutiveSkills: number;
    DyslexiaTscoreTotal: number;
}

export interface StudentUAQuestionAnswersDTO {
    StudentFullName: string;
    PeriodName: string;
    RaterFullName: string;
    ParentsNames: string[];
    BasicReading: CategoryQuestionsDTO;
    ReadingComprehension: CategoryQuestionsDTO;
    Writing: CategoryQuestionsDTO;
    ExecutiveSkills: CategoryQuestionsDTO;
    GradeLevel: CategoryQuestionsDTO;
    Comment: string;
    HasComment: boolean;
    RaterQuestionIdMeanValue: number[];
    RaterQuestionIdStDev: number[];
    ParentQuestionIdMeanValue: number[];
    ParentQuestionIdStDev: number[];
}

export interface CategoryQuestionsDTO {
    CategoryName: string;
    CategoryTscoreRater: number;
    CategoryTscoreParent1: number;
    CategoryTscoreParent2: number;
    QuestionsStudent: QuestionsAnswersStudentsDTO[];
    Questions: QuestionAnswersDTO[];
    Comment: string;
    HasComment: boolean;
}


export interface QuestionAnswersDTO {
    QuestionId: string;
    QuestionRater: string;
    QuestionParent: string;
    AnswerRater: number;
    AnswerParent: number[];
}

export interface QuestionsAnswersStudentsDTO {
    QuestionId: string;
    QuestionStudent: string;
    AnswerStudent: number;
}

export interface ParentLetterDTO {
    StudentFullName: string;
    StudentId: string;
    SchoolId: number;
    SchoolName: string;
    StudentGrade: string;
    AssessmentDate: string;
    RaterRole: string;
    BasicReading: ParentLetterResultDTO;
    ReadingComprehension: ParentLetterResultDTO;
    Writing: ParentLetterResultDTO;
    ExecutiveSkills: ParentLetterResultDTO;
}

export interface ParentLetterResultDTO {
    TScore: number;
    Percentile: number;
}

export class StudentImportRawData {
    StudentId: string;

    TakeMath: boolean;
    TakeVerbal: boolean;
    VerbalQuestions: SurveyQuestionAnswer[];
    MathQuestions: SurveyQuestionAnswer[];

    Level: string;

    TakeVocabulary: boolean;
    TakeSpelling: boolean;
    TakeLangMech: boolean;
    TakeReadCompr: boolean;
    TakeMathComp: boolean;
    TakeMathAppl: boolean;
    VocabularyQuestions: SurveyQuestionAnswer[];
    ReadingComprehensionQuestions: SurveyQuestionAnswer[];
    SpellingQuestions: SurveyQuestionAnswer[];
    LanguageMechanicsQuestions: SurveyQuestionAnswer[];
    MathComputationQuestions: SurveyQuestionAnswer[];
    MathApplicationQuestions: SurveyQuestionAnswer[];

    constructor(studentId: string) {
        this.StudentId = studentId;
        this.Level = "";

        this.TakeMath = false;
        this.TakeVerbal = false;
        this.TakeVocabulary = false;
        this.TakeSpelling = false;
        this.TakeLangMech = false;
        this.TakeReadCompr = false;
        this.TakeMathAppl = false;
        this.TakeMathComp = false;

        this.MathQuestions = [];
        this.VerbalQuestions = [];
        this.VocabularyQuestions = [];
        this.ReadingComprehensionQuestions = [];
        this.SpellingQuestions = [];
        this.LanguageMechanicsQuestions = [];
        this.MathApplicationQuestions = [];
        this.MathComputationQuestions = [];
    }
}

export class ScoringAnswers {
    AssessmentId: number;
    StudentId: number;
    TakeMath: boolean;
    TakeVerbal: boolean;
    VerbalQuestions: SurveyQuestionAnswer[];
    MathQuestions: SurveyQuestionAnswer[];

    Level: string;
    // Form: string;
    // Timed: boolean;
    TakeVocabulary: boolean;
    TakeSpelling: boolean;
    TakeLangMech: boolean;
    TakeReadCompr: boolean;
    TakeMathComp: boolean;
    TakeMathAppl: boolean;
    VocabularyQuestions: SurveyQuestionAnswer[];
    ReadingComprehensionQuestions: SurveyQuestionAnswer[];
    SpellingQuestions: SurveyQuestionAnswer[];
    LanguageMechanicsQuestions: SurveyQuestionAnswer[];
    MathComputationQuestions: SurveyQuestionAnswer[];
    MathApplicationQuestions: SurveyQuestionAnswer[];
}


export class ScoringEditDTO {
    Period: UAPeriodScoringDTO;
    StudentName: string;
    StudentGradeId: number;

    VerbalQuestions: SurveyQuestionAnswer[];
    MathQuestions: SurveyQuestionAnswer[];

    Level: string;

    ComprQuestionsCount: ComprQuestionsCount[];
    VocabularyQuestions: SurveyQuestionAnswer[];
    ReadingComprehensionQuestions: SurveyQuestionAnswer[];
    SpellingQuestions: SurveyQuestionAnswer[];
    LanguageMechanicsQuestions: SurveyQuestionAnswer[];
    MathComputationQuestions: SurveyQuestionAnswer[];
    MathApplicationQuestions: SurveyQuestionAnswer[];
}