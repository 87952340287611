import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ProgressMonitoringPlanTestDTO, PMAddEditDTO, ProgressMonitoringFlexItemAddEditDTO, ProgressMonitoringFlexItemDetailsDTO, ProgressMonitoringFlexItemMainDTO, ProgressMonitoringPlanDetailsDTO, ProgressMonitoringPlanFormData, ProgressMonitoringPlanMainDTO, ProgressMonitoringAssessmentMainDTO, ProgressMonitoringAssessmentFlexItemDTO, ProgressMonitoringPlanResultDTO, ProgressMonitoringPlanEmailDTO } from '@app/CoreModule/Models/ProgressMonitoring';
import { SurveyStudentAnswer } from '../Models/Survey';
import { UAPeriodMainDTO } from '@app/CoreModule/Models/UAPeriod';

import { map, shareReplay } from 'rxjs/operators';


@Injectable()
export class ProgressMonitoringService {

  constructor(private http: HttpClient, @Inject('BASE_URL') public baseUrl: string) { }

  getAllPlansMain() {

    return this.http.get<ProgressMonitoringPlanMainDTO[]>(this.baseUrl + 'api/ProgressMonitoring/GetAllPlansMain').pipe(
      map(result => result),
      shareReplay()
    );
  }

  getPlanDetails(id: number) {
    return this.http.get<ProgressMonitoringPlanDetailsDTO>(this.baseUrl + `api/ProgressMonitoring/GetPlanDetailsById/${id}`).pipe(
      map(result => result),
      shareReplay()
    );
  }

  getPlanEdit(id: number) {
    return this.http.get<PMAddEditDTO>(this.baseUrl + `api/ProgressMonitoring/GetPlanByIdEdit/${id}`).pipe(
      map(result => result),
      shareReplay()
    );
  }

  getPMFormData() {
    return this.http.get<ProgressMonitoringPlanFormData>(this.baseUrl + 'api/ProgressMonitoring/GetPMFormData').pipe(
      map(result => result),
      shareReplay()
    );
  }

  addPlan(plan: PMAddEditDTO) {
    return this.http.post(this.baseUrl + `api/ProgressMonitoring/AddPlan`, plan).pipe(
      map(result => result),
      shareReplay()
    );
  }

  updatePlan(plan: PMAddEditDTO) {
    return this.http.post(this.baseUrl + `api/ProgressMonitoring/UpdatePlan`, plan).pipe(
      map(result => result),
      shareReplay()
    );
  }

  addPlanDate(planId: number, studentId: number) {
    return this.http.post(this.baseUrl + `api/ProgressMonitoring/AddPlanDate/${planId}/${studentId}`, null).pipe(
      map(result => result),
      shareReplay()
    );
  }

  getAllFlexItemsMain() {
    return this.http.get<ProgressMonitoringFlexItemMainDTO[]>(this.baseUrl + 'api/ProgressMonitoring/GetAllFlexItemsMain').pipe(
      map(result => result),
      shareReplay()
    );
  }

  getFlexItemDetails(itemId: number) {
    return this.http.get<ProgressMonitoringFlexItemDetailsDTO>(this.baseUrl + `api/ProgressMonitoring/GetFlexItemDetails/${itemId}`).pipe(
      map(result => result),
      shareReplay()
    );
  }

  getFlexItemByIdEdit(itemId: number) {
    return this.http.get<ProgressMonitoringFlexItemAddEditDTO>(this.baseUrl + `api/ProgressMonitoring/GetFlexItemByIdEdit/${itemId}`).pipe(
      map(result => result),
      shareReplay()
    );
  }

  addFlexItem(plan: ProgressMonitoringFlexItemAddEditDTO) {
    return this.http.post(this.baseUrl + `api/ProgressMonitoring/AddFlexItem`, plan).pipe(
      map(result => result),
      shareReplay()
    );
  }

  updateFlexItem(plan: ProgressMonitoringFlexItemAddEditDTO) {
    return this.http.post(this.baseUrl + `api/ProgressMonitoring/UpdateFlexItem`, plan).pipe(
      map(result => result),
      shareReplay()
    );
  }

  deleteFlexItem(itemId: number) {
    return this.http.get(this.baseUrl + `api/ProgressMonitoring/DeleteFlexItem/${itemId}`).pipe(
      map(result => result),
      shareReplay()
    );
  }

  getPMTestMainData(planId: number, token: string) {
    return this.http.get<ProgressMonitoringAssessmentMainDTO>(this.baseUrl + `api/ProgressMonitoring/GetPMTestMainData/${planId}/${token}`).pipe(
      map(result => result),
      shareReplay()
    );
  }

  getPMTestQuestions(planId: number, token: string, itemId: number) {
    return this.http.get<ProgressMonitoringAssessmentFlexItemDTO>(this.baseUrl + `api/ProgressMonitoring/GetPMTestQuestions/${planId}/${token}/${itemId}`).pipe(
      map(result => result),
      shareReplay()
    );
  }

  submitPMTestAnswers(planId: number, token: string, itemId: number, answers: SurveyStudentAnswer[]) {
    return this.http.post(this.baseUrl + `api/ProgressMonitoring/SubmitPMTestAnswers/${planId}/${token}/${itemId}`, answers).pipe(
      map(result => result),
      shareReplay()
    );
  }

  getPlanStudentResults(planId: number, studentId: number) {
    return this.http.get<ProgressMonitoringPlanResultDTO>(this.baseUrl + `api/ProgressMonitoring/GetPlanStudentResults/${planId}/${studentId}`).pipe(
      map(result => result),
      shareReplay()
    );
  }

  getPlanStudentResultsDetailedInfo(planDateId: number, studentId: number) {
    return this.http.get<any>(this.baseUrl + `api/ProgressMonitoring/GetResultsDetailed/${planDateId}/${studentId}`).pipe(
      map(result => result),
      shareReplay()
    );
  }

  sendAllStudentsNotificationEmail(data: ProgressMonitoringPlanEmailDTO) {
    return this.http.post(this.baseUrl + `api/ProgressMonitoring/SendAllStudentsNotificationEmail`, data).pipe(
      map(result => result),
      shareReplay()
    );
  }

  sendStudentNotificationEmail(data: ProgressMonitoringPlanEmailDTO) {
    return this.http.post(this.baseUrl + `api/ProgressMonitoring/SendStudentNotificationEmail`, data).pipe(
      map(result => result),
      shareReplay()
    );
  }

  getPlanTestStudentAnswers(planDateId: number, studentId: number) {
    return this.http.get<any>(this.baseUrl + `api/ProgressMonitoring/GetPlanTestStudentAnswers/${planDateId}/${studentId}`).pipe(
      map(result => result),
      shareReplay()
    );
  }

  addEditStudentAnswers(planDateId: number, studentId: number, items: any) {
    return this.http.post(this.baseUrl + `api/ProgressMonitoring/AddEditStudentAnswers/${planDateId}/${studentId}`, items).pipe(
      map(result => result),
      shareReplay()
    );

  }

}
