import { Component, Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';

import { map, shareReplay } from 'rxjs/operators';

import { UaStatusReport, UaDataOverviewPyramidsDTO, UaDataOverviewStudentScoresDTO, UaDataOverviewSchoolsAverageDTO, ProgressMonitoringClassScoresDTO, ProgressMonitoringPeriodAverageDTO, ScaleScorePerPeriodDTO, ProgressMonitoringGroupScaleScoresDTO, UaDataOverviewGendersAverageDTO, UaDataOverviewEthnicitiesAverageDTO, UaDataOverviewRacesAverageDTO } from '../Models/Report';
import { UAPeriodDTO } from '../Models/UAPeriod';
import { SchoolNameDTO } from '../Models/School';
import { Grade } from '../Models/Grade';

export class OtherReportsData {
    FormType: number;
    UAPeriod: number;
    Schools: number[];
    Grades: number[];
    Teachers: number[];
    Scale: number;
    AllUAPeriods: UAPeriodDTO[];
    AllSchools: SchoolNameDTO[];
    AllGrades: Grade[];
    AllStaff: any[];

    constructor() {
        this.FormType = 1;
        this.UAPeriod = 0;
        this.Schools = [];
        this.Grades = [];
        this.Teachers = [];
        this.Scale = 11;
        this.AllUAPeriods = [];
        this.AllSchools = [];
        this.AllGrades = [];
        this.AllStaff = [];
    }
}

@Injectable()
export class ReportsService {

    constructor(private http: HttpClient, @Inject('BASE_URL') public baseUrl: string) { }

    reportsFormType = new BehaviorSubject(1);
    currentFormType = this.reportsFormType.asObservable();
    changeReportsFormType(message: number) {

        this.reportsFormType.next(message);

    }

    otherReportsData = new BehaviorSubject(new OtherReportsData());
    currentOtherReportsData = this.otherReportsData.asObservable();
    changeOtherReportsData(message: OtherReportsData) {

        this.otherReportsData.next(message);
    }

    getAllSchoolsUaStatusReport(type: number) {
        return this.http.get<UaStatusReport[]>(this.baseUrl + `api/Reports/GetAllSchoolsUaStatusReport/${type}`).pipe(
            map(result => result),
            shareReplay()
        );
    }

    getSchoolUaStatusReport(type: number, schoolId: number) {
        return this.http.get<UaStatusReport[]>(this.baseUrl + `api/Reports/GetSchoolUaStatusReport/${type}/${schoolId}`).pipe(
            map(result => result),
            shareReplay()
        );
    }

    getAssessmentResults(type: number, periodId: number, schoolsIds: number[], teachersIds: number[], gradesIds: number[]) {
        return this.http.post<UaDataOverviewPyramidsDTO[]>(this.baseUrl + 'api/Reports/GetAssessmentResults', { UserType: type, PeriodId: periodId, SchoolsIds: schoolsIds, TeachersIds: teachersIds, GradesIds: gradesIds }).pipe(
            shareReplay()
        );
    }

    getStudentsWithAssessmentResults(type: number, periodId: number, schoolsIds: number[], gradesIds: number[], teachersIds: number[]) {
        return this.http.post<UaDataOverviewStudentScoresDTO[]>(this.baseUrl + 'api/Reports/GetStudentsWithAssessmentResults', { UserType: type, PeriodId: periodId, SchoolsIds: schoolsIds, GradesIds: gradesIds, TeachersIds: teachersIds }).pipe(
            shareReplay()
        );
    }

    getClassStudentScoresPerScale(type: number, schoolsIds: number[], teachersIds: number[], gradesIds: number[], categoryId: number) {
        return this.http.post<ProgressMonitoringClassScoresDTO[]>(this.baseUrl + 'api/Reports/GetClassStudentScoresPerScale', { UserType: type, SchoolsIds: schoolsIds, TeachersIds: teachersIds, GradesIds: gradesIds, CategoryId: categoryId }).pipe(
            shareReplay()
        );
    }

    getSchoolAverageScorePerPeriod(type: number, periodsIds: number[], schoolsIds: number[], categoryId: number) {
        return this.http.post<ProgressMonitoringPeriodAverageDTO[]>(this.baseUrl + 'api/Reports/GetSchoolAverageScorePerPeriod', { UserType: type, PeriodsIds: periodsIds, SchoolsIds: schoolsIds, CategoryId: categoryId }).pipe(
            shareReplay()
        );
    }

    getStudentScaleScorePerPeriod(studentId: number) {
        return this.http.get<ScaleScorePerPeriodDTO[][]>(this.baseUrl + `api/Reports/GetStudentScaleScorePerPeriod/${studentId}`).pipe(
            map(result => result),
            shareReplay()
        );
    }

    getPeriodGroupScaleScores(type: number, schoolsIds: number[], teachersIds: number[], gradesIds: number[]) {
        return this.http.post<ProgressMonitoringGroupScaleScoresDTO>(this.baseUrl + 'api/Reports/GetPeriodGroupScaleScores', { UserType: type, SchoolsIds: schoolsIds, TeachersIds: teachersIds, GradesIds: gradesIds }).pipe(
            shareReplay()
        );
    }

    /* Other Reports */
    getSchoolsAverageScores(type: number, periodId: number, categoryId: number, schoolsIds: number[], gradesIds: number[], teachersIds: number[]) {
        return this.http.post<UaDataOverviewSchoolsAverageDTO[]>(this.baseUrl + 'api/Reports/GetSchoolsAverageScores', { UserType: type, PeriodId: periodId, CategoryId: categoryId, SchoolsIds: schoolsIds, GradesIds: gradesIds, TeachersIds: teachersIds }).pipe(
            shareReplay()
        );
    }

    getGendersAverageScores(type: number, periodId: number, categoryId: number, schoolsIds: number[], gradesIds: number[], teachersIds: number[], races: string[], ethnicities: string[]) {
        return this.http.post<UaDataOverviewGendersAverageDTO[]>(this.baseUrl + 'api/Reports/GetGendersAverageScores', { UserType: type, PeriodId: periodId, CategoryId: categoryId, SchoolsIds: schoolsIds, GradesIds: gradesIds, TeachersIds: teachersIds, Races: races, Ethnicities: ethnicities }).pipe(
            shareReplay()
        );
    }

    getEthnicitiesAverageScores(type: number, periodId: number, categoryId: number, schoolsIds: number[], gradesIds: number[], teachersIds: number[], genders: string[], races: string[]) {
        return this.http.post<UaDataOverviewEthnicitiesAverageDTO[]>(this.baseUrl + 'api/Reports/GetEthnicitiesAverageScores', { UserType: type, PeriodId: periodId, CategoryId: categoryId, SchoolsIds: schoolsIds, GradesIds: gradesIds, TeachersIds: teachersIds, Genders: genders, Races: races }).pipe(
            shareReplay()
        );
    }

    getRacesAverageScores(type: number, periodId: number, categoryId: number, schoolsIds: number[], gradesIds: number[], teachersIds: number[], genders: string[], ethnicities: string[]) {
        return this.http.post<UaDataOverviewRacesAverageDTO[]>(this.baseUrl + 'api/Reports/GetRacesAverageScores', { UserType: type, PeriodId: periodId, CategoryId: categoryId, SchoolsIds: schoolsIds, GradesIds: gradesIds, TeachersIds: teachersIds, Genders: genders, Ethnicities: ethnicities }).pipe(
            shareReplay()
        );
    }

}

