import { Component, OnInit } from '@angular/core';


import { MatDialog, MatDialogConfig } from "@angular/material/dialog";


import { ActivatedRoute } from '@angular/router';

import * as jspdf from 'jspdf';

import { Globals } from '@app/globals';
import { StudentUAQuestionAnswersDTO, CategoryQuestionsDTO, QuestionAnswersDTO } from '@app/CoreModule/Models/Assessment';
import { UsersService, AuthService, AssessmentService } from '@app/CoreModule/Services';

@Component({
    selector: 'app-show-assessment',
    templateUrl: './show-assessment.component.html',
    styleUrls: ['./show-assessment.component.css']
})
export class ShowAssessmentComponent implements OnInit {
    student: any = {};
    data: StudentUAQuestionAnswersDTO = {} as StudentUAQuestionAnswersDTO;
    parentsAnswersCount = 0;
    showParentAnswers = false;
    SelectUADataToShow = ['Teacher UA Data', 'Parent UA Data'];
    SelectedUAData = this.SelectUADataToShow[0];
    ShowTeacherUAData = false;
    ShowParentUAData = false;

    radioOptions1 = [
        { Name: "Never", Value: 1 },
        { Name: "Rarely", Value: 2 },
        { Name: "Sometimes", Value: 3 },
        { Name: "Often", Value: 4 },
        { Name: "Very Often", Value: 5 },
    ];
    radioOptions2 = [
        { Name: "Not Sure", Value: -1 },
        { Name: "No", Value: 0 },
        { Name: "Yes", Value: 1 }
    ];

    constructor(public globals: Globals, private filesService: UsersService, private authService: AuthService, private assessmentService: AssessmentService, public dialog: MatDialog, private route: ActivatedRoute) {
    }

    ngOnInit() {
        var studentId = +this.route.snapshot.paramMap.get('id');
        var periodId = +this.route.snapshot.paramMap.get('periodId');
        this.assessmentService.getStudentUAAnswers(studentId, periodId).subscribe({
            next: (result: StudentUAQuestionAnswersDTO) => {
                this.data = result;
                this.ShowTeacherUAData = true;
                this.parentsAnswersCount = result['BasicReading']['Questions'][0]['AnswerParent'].length;
            },
            error: () => this.authService.alertError(`An error occured while requesting data for this student. Please try again.`)
        });
    }

    saveCategoryComment(index, category: CategoryQuestionsDTO) {
        var studentId = +this.route.snapshot.paramMap.get('id');
        var periodId = +this.route.snapshot.paramMap.get('periodId');
        this.assessmentService.saveCategoryComment(studentId, periodId, index, category.Comment).subscribe({
            next: (result: StudentUAQuestionAnswersDTO) => {
                this.data = result;
                this.parentsAnswersCount = result['BasicReading']['Questions'][0]['AnswerParent'].length;
                this.authService.alertSuccess(`Comment successfuly saved for scale ${category.CategoryName}.`);
            },
            error: () => this.authService.alertError(`Comment failed to be saved for scale ${category.CategoryName}. Please try again.`),
        })
    }

    saveGeneralComment() {
        var studentId = +this.route.snapshot.paramMap.get('id');
        var periodId = +this.route.snapshot.paramMap.get('periodId');
        this.assessmentService.saveGeneralComment(studentId, periodId, this.data.Comment).subscribe({
            next: (result: StudentUAQuestionAnswersDTO) => {
                this.data = result;
                this.parentsAnswersCount = result['BasicReading']['Questions'][0]['AnswerParent'].length;
                this.authService.alertSuccess(`Comment successfully saved.`);
            },
            error: () => this.authService.alertError(`Comment failed to be saved. Please try again.`),
        })
    }

    ChangeUAData() {
        if (this.SelectedUAData == 'Parent UA Data') {
            this.ShowTeacherUAData = false;
            this.ShowParentUAData = true;
        }
        else {
            this.ShowTeacherUAData = true;
            this.ShowParentUAData = false;
        }
    }

    /*checkParentCheckbox(list) {
        if (this.showParentAnswers) return list;
        else return [];
    }*/


    getQuestionAnswerClass(questionId, answer) {
        if (this.ShowTeacherUAData) {
            if (answer > Math.round(this.data['RaterQuestionIdMeanValue'][questionId - 1] + this.data['RaterQuestionIdStDev'][questionId - 1])) return 'strength';
            else if (answer < Math.round(this.data['RaterQuestionIdMeanValue'][questionId - 1] - this.data['RaterQuestionIdStDev'][questionId - 1])) return 'atRisk';
            else return 'typical';
        } else if (this.ShowParentUAData) {
            if (answer > Math.round(this.data['ParentQuestionIdMeanValue'][questionId - 1] + this.data['ParentQuestionIdStDev'][questionId - 1])) return 'strength';
            else if (answer < Math.round(this.data['ParentQuestionIdMeanValue'][questionId - 1] - this.data['ParentQuestionIdStDev'][questionId - 1])) return 'atRisk';
            else return 'typical';
        }
    }


    categories = { 'AttentionNorm': 'ATTENTION', 'InititiativeNorm': 'INITIATIVE', 'SocialSkillsNorm': 'SOCIAL SKILLS', 'SelfControlNorm': 'SELF CONTROL', 'HomeSchoolNorm': 'Home-School Relationship' }

    getIndicatorName(indicator) {
        return this.categories[indicator];
    }

    getLevelOfClass(ua, indicator) {
        if (ua) {
            if (indicator == 'SelfControl' || indicator == 'HomeSchool') {
                if (ua['TScore'] >= 60) return 'strength';
                else if (ua['TScore'] >= 60) return 'typical';
                else return 'atRisk';
            }
            else {
                if (ua['TScore'] >= 70) return 'highrisk';
                else if (ua['TScore'] >= 60) return 'somerisk';
                else return 'lowrisk';
            }
        }
        else return 'empty';
    }


    extractPdf() {
        this.filesService.getFontNormal().then(
            data => {
                if (data) {
                    const downloadedFile = new Blob([data['body']], { type: data['body'].type });
                    var reader = new FileReader();
                    reader.onload = () => {

                        this.filesService.getFontBold().then(
                            data => {
                                if (data) {
                                    const downloadedFile = new Blob([data['body']], { type: data['body'].type });
                                    var reader2 = new FileReader();
                                    reader2.onload = () => {
                                        this.extractPdfFonts(reader.result, reader2.result); //function edw
                                    }
                                    reader2.readAsText(downloadedFile);
                                }
                            },
                            error => this.authService.alertError("An error occured while downloading file. Please try again.")
                        );
                    }
                    reader.readAsText(downloadedFile);
                }
            },
            error => {
                this.authService.alertError("An error occured while downloading file. Please try again.")
            }
        );

    }

    getAnswer(QuestionId: string, Answer: number) {
        if (+QuestionId < 30) return Answer;
        else if (+QuestionId == 30) {
            if (Answer == 1) return "Yes";
            else if (Answer == 0) return "No";
            else return "Not Sure";
        }
        else {
            if (Answer == 0) return "Yes";
            else if (Answer == 1) return "No";
            else return "Not Sure";
        }
    }

    async extractPdfFonts(normalFont, boldFont) {
        if (!this.data || !this.data['StudentFullName']) return;


        var doc = new jspdf.jsPDF("p", "mm", "a4"); //width: 210


        doc.addFileToVFS('Arimo-Regular-Normal.ttf', normalFont);
        doc.addFont("Arimo-Regular-Normal.ttf", "Arimo-Regular", "normal");
        doc.addFileToVFS('Arimo-Regular-Bold.ttf', boldFont);
        doc.addFont("Arimo-Regular-Bold.ttf", "Arimo-Regular", "bold");

        doc.setFont("Arimo-Regular");

        doc.setFont(undefined, 'bold');


        var position = 28;

        doc.setFontSize(24);
        doc.setTextColor(0, 0, 0);
        doc.text("STUDENT ASSESSMENT", 10, position);

        doc.setDrawColor(2, 15, 152);
        doc.setLineWidth(0.6);
        doc.line(5, position + 2, 205, position + 2);


        doc.setFont(undefined, 'normal');
        doc.setFontSize(11);
        doc.setTextColor(0, 0, 0);
        doc.text("Student:", 10, position + 15);
        doc.setFont(undefined, 'normal');

        doc.setTextColor(0, 0, 255);
        doc.text(this.data['StudentFullName'], 45, position + 15);

        doc.setTextColor(0, 0, 0);
        doc.text("UA Period:", 10, position + 22);
        doc.setTextColor(0, 0, 255);
        doc.text(this.data['PeriodName'], 45, position + 22);

        doc.setTextColor(0, 0, 0);
        doc.text("Rater:", 10, position + 29);
        doc.setTextColor(0, 0, 255);
        doc.text(this.data['RaterFullName'], 45, position + 29);

        this.data['ParentsNames'].forEach((name, index) => {
            doc.setTextColor(0, 0, 0);
            doc.text(`Parent/Guardian ${index + 1}:`, 10, position + 36 + index * 7);
            doc.setTextColor(0, 0, 255);
            doc.text(name, 45, position + 36 + index * 7);
        });

        position += 45 + 7 * this.data['ParentsNames'].length;



        y = position;
        x = 10;

        width = 30;

        doc.setFont(undefined, 'bold');

        doc.setFontSize(13);
        doc.setTextColor(0, 0, 0);
        var text = "ITEM SCORE LEGEND";
        doc.text(text, 10, y);

        y += 8;
        x = 16;

        /*
        doc.setFontStyle('bold');
        doc.setFontSize(11);
        doc.setTextColor(0, 0, 0);
        var text = "Basic Reading, Reading Comprehension, Writing, Emotional Self-Regulation";
        doc.text(text, x, y);

        y += 3;
*/
        var rowHeight = 9;
        doc.setDrawColor(0, 0, 255);
        doc.setLineWidth(0.2);
        doc.rect(x, y, width, rowHeight);
        doc.rect(x + width, y, width, rowHeight);
        doc.rect(x + 2 * width, y, width, rowHeight);
        doc.rect(x + 3 * width, y, width, rowHeight);
        doc.rect(x + 4 * width, y, width, rowHeight);

        y -= 1;

        doc.setLineWidth(0.4);
        doc.setDrawColor(0)
        doc.setFontSize(10);

        doc.setFont(undefined, 'normal');

        doc.circle(x + 5, y + 5.5, 2.5)
        text = "1   Never";
        doc.text(text, x + 4, y + 7);

        doc.circle(x + 5 + width, y + 5.5, 2.5)
        text = "2   Rarely"
        doc.text(text, x + 4 + width, y + 7);

        doc.circle(x + 5 + 2 * width, y + 5.5, 2.5)
        text = "3   Sometimes";
        doc.text(text, x + 4 + 2 * width, y + 7);

        doc.circle(x + 5 + 3 * width, y + 5.5, 2.5)
        text = "4   Often";
        doc.text(text, x + 4 + 3 * width, y + 7);

        doc.circle(x + 5 + 4 * width, y + 5.5, 2.5)
        text = "5   Very Often";
        doc.text(text, x + 4 + 4 * width, y + 7);

        y += rowHeight + 10;

        /*
        doc.setFontStyle('bold');
        doc.setFontSize(11);
        doc.setTextColor(0, 0, 0);
        var text = "Grade Level";
        doc.text(text, x, y);

        y += 3;

        doc.setDrawColor(0, 0, 255);
        doc.setLineWidth(0.2);
        doc.rect(x, y, width, rowHeight);
        doc.rect(x + width, y, width, rowHeight);
        doc.rect(x + 2 * width, y, width, rowHeight);

        y -= 1;

        doc.setLineWidth(0.4);
        doc.setDrawColor(0)
        doc.setFontSize(10);
        doc.setFontStyle('normal');
        doc.circle(x + 5, y + 5.5, 2.5)
        text = "0   Not Sure";
        doc.text(text, x + 4, y + 7);

        doc.circle(x + 5 + width, y + 5.5, 2.5)
        text = "0   No"
        doc.text(text, x + 4 + width, y + 7);

        doc.circle(x + 5 + 2 * width, y + 5.5, 2.5)
        text = "1   Yes";
        doc.text(text, x + 4 + 2 * width, y + 7);
*/

        var width = 66;
        var height = 8;
        var x = 10;
        var y = position + 40;

        doc.setFont(undefined, 'bold');

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(13);
        text = "SCORES TABLE INFO";
        doc.text(text, x, y - 3);

        doc.setFontSize(11);
        doc.setLineWidth(0.2);
        doc.setDrawColor(0, 0, 255);
        doc.setFillColor(218, 218, 218);
        doc.rect(x, y, width, height, 'FD');
        doc.setTextColor(0, 0, 0);

        doc.setFont(undefined, 'normal');

        text = "Levels of Risk";
        doc.text(text, x + this.centeriseText(text, width, doc), y + height / 2 + 1);

        doc.setTextColor(0, 0, 0);
        doc.setFillColor(255, 51, 89);
        doc.rect(x, y + height, width, height, 'FD');
        text = this.globals.strength;
        doc.text(text, x + this.centeriseText(text, width, doc), y + 3 * height / 2 + 1);

        doc.setFillColor(255, 255, 59);
        doc.rect(x, y + 2 * height, width, height, 'FD');
        text = this.globals.atRisk;
        doc.text(text, x + this.centeriseText(text, width, doc), y + 5 * height / 2 + 1);

        doc.setFillColor(169, 208, 142);
        doc.rect(x, y + 3 * height, width, height, 'FD');
        text = this.globals.typical;
        doc.text(text, x + this.centeriseText(text, width, doc), y + 7 * height / 2 + 1);

        doc.setFillColor(112, 173, 71);
        doc.rect(x, y + 4 * height, width, height, 'FD');
        text = this.globals.strength;
        doc.text(text, x + this.centeriseText(text, width, doc), y + 9 * height / 2 + 1);


        if (this.ShowTeacherUAData && this.data['RaterQuestionIdMeanValue'].length > 0) {
            var index = 0;
            //------------------------------------
            [this.data['BasicReading'], this.data['ReadingComprehension'], this.data['Writing'], this.data['ExecutiveSkills'], this.data['GradeLevel']].forEach(category => {
                doc.addPage();

                x = 10;
                y = 16;

                doc.setFontSize(14);

                doc.setFont(undefined, 'bold');

                doc.setTextColor(0, 0, 0);
                doc.text(category['CategoryName'], x, y);

                y += 8;
                var rowHeight = 10;
                var textHeight = doc.getTextDimensions('Text')['h'];
                var questionWidth = 4 + this.calculateTextWidth(category['Questions'].map(a => a['QuestionRater']).reduce((a, b) => a.length > b.length ? a : b, ''), doc)
                if (questionWidth > 128) questionWidth = 128;
                var idWidth = 7;
                var scoreWidth = 17;
                var descriptionWidth = 22;
                var parentWidth = 0;

                if (index < 4) {
                    doc.setDrawColor(0, 0, 255);
                    doc.setFillColor(163, 207, 255);
                    doc.setLineWidth(0.2);
                    var text = "Result";
                    var textWidth = this.calculateTextWidth(text, doc) + 4;
                    doc.rect(x, y, idWidth + questionWidth / 2, rowHeight, 'FD');

                    doc.setFontSize(10);

                    doc.setFont(undefined, 'bold');

                    doc.setTextColor(0, 0, 0);
                    doc.text(text, x + this.centeriseText(text, idWidth + questionWidth / 2, doc), y + ((rowHeight + textHeight) / 2) - 1);

                    doc.setDrawColor(0, 0, 255);
                    doc.setFillColor(163, 207, 255);
                    doc.setLineWidth(0.2);
                    doc.rect(x + idWidth + questionWidth / 2, y, questionWidth / 2 + scoreWidth + descriptionWidth, rowHeight, 'FD');


                    doc.setFont(undefined, 'bold');

                    doc.setFontSize(10);
                    var space = 4;
                    var tscore = category['CategoryTscoreRater'];
                    text = "T-Score = " + tscore.toString();
                    doc.text(text, x + idWidth + questionWidth / 2 + this.centeriseText(text, 30, doc), y + ((rowHeight + textHeight) / 2) - 1);

                    text = this.getLevelByScore(tscore);
                    this.setBackgroundFillColor(tscore, doc);
                    doc.setDrawColor(0, 0, 255);
                    doc.setLineWidth(0.2);
                    doc.rect(x + idWidth + questionWidth / 2 + 2 * space + 26 - 2, y, 30, rowHeight, 'FD');
                    doc.text(text, x + idWidth + questionWidth / 2 + 2 * space + 26 - 2 + this.centeriseText(text, 30, doc), y + ((rowHeight + textHeight) / 2) - 1);

                    doc.setDrawColor(0, 0, 255);
                    doc.setLineWidth(0.01);
                    doc.line(x + idWidth + questionWidth / 2 + (space + 26) + space / 2, y, x + idWidth + questionWidth / 2 + (space + 26) + space / 2, y + rowHeight);
                    doc.line(x + idWidth + questionWidth / 2 + 2 * (space + 26) + space / 2, y, x + idWidth + questionWidth / 2 + 2 * (space + 26) + space / 2, y + rowHeight);

                    y += rowHeight;
                }

                x = 10;
                rowHeight = 9;

                doc.setDrawColor(0, 0, 255);
                doc.setFillColor(163, 207, 255);
                doc.setLineWidth(0.2);
                doc.rect(x, y, idWidth + questionWidth, rowHeight, 'FD');

                doc.setFontSize(10);

                doc.setFont(undefined, 'bold');

                doc.setTextColor(0, 0, 0);
                text = "Item";
                doc.text(text, x + this.centeriseText(text, idWidth + questionWidth, doc), y + ((rowHeight + textHeight) / 2) - 1);
                x += idWidth + questionWidth;

                doc.setDrawColor(0, 0, 255);
                doc.setFillColor(163, 207, 255);
                doc.setLineWidth(0.2);
                doc.rect(x, y, scoreWidth, rowHeight, 'FD');

                doc.setFontSize(10);

                doc.setFont(undefined, 'bold');

                doc.setTextColor(0, 0, 0);
                text = index < 4 ? "Score" : "Answer";
                doc.text(text, x + this.centeriseText(text, scoreWidth, doc), y + ((rowHeight + textHeight) / 2) - 1);
                x += scoreWidth;

                if (index < 4) {
                    doc.setDrawColor(0, 0, 255);
                    doc.setFillColor(163, 207, 255);
                    doc.setLineWidth(0.2);
                    doc.rect(x, y, descriptionWidth, rowHeight, 'FD');

                    doc.setFontSize(10);

                    doc.setFont(undefined, 'bold');

                    doc.setTextColor(0, 0, 0);
                    var text = "Descriptor";
                    doc.text(text, x + this.centeriseText(text, descriptionWidth, doc), y + ((rowHeight + textHeight) / 2) - 1);
                }

                doc.setFontSize(9);

                var extraRowHeight = this.showParentAnswers ? 7 : 1;
                rowHeight += extraRowHeight;
                y -= extraRowHeight;

                var i = 1;
                category['Questions'].forEach(obj => {
                    doc.setDrawColor(0, 0, 255);
                    doc.setLineWidth(0.2);
                    doc.rect(10, y + i * rowHeight, idWidth, rowHeight);
                    doc.rect(10 + idWidth, y + i * rowHeight, questionWidth, rowHeight);
                    doc.rect(10 + idWidth + questionWidth, y + i * rowHeight, scoreWidth, rowHeight);

                    if (index < 4) {
                        this.setBackgroundDescription(obj['QuestionId'], obj['AnswerRater'], doc);
                        doc.rect(10 + idWidth + questionWidth + scoreWidth, y + i * rowHeight, descriptionWidth, rowHeight, 'FD');
                    }


                    doc.setFont(undefined, 'normal');

                    doc.setTextColor(0, 0, 0);
                    var id = obj['QuestionId'];
                    doc.text(id, 10 + this.centeriseText(id, idWidth, doc), y + i * rowHeight + ((rowHeight + textHeight) / 2) - 1);

                    if (this.calculateTextWidth(obj['QuestionRater'], doc) < questionWidth - 2) {
                        doc.text(obj['QuestionRater'], 10 + idWidth + this.centeriseText(obj['QuestionRater'], questionWidth, doc), y + i * rowHeight + ((rowHeight + textHeight) / (this.showParentAnswers ? 4 : 2)) - (this.showParentAnswers ? 0 : 1));
                    }
                    else {
                        var first = obj['QuestionRater'].split(" ");
                        var second = first.splice(Math.floor(first.length / 2) + 1).join(" ");

                        doc.text(first.join(" "), 10 + idWidth + this.centeriseText(first, questionWidth, doc), y + i * rowHeight + ((rowHeight + textHeight) / (this.showParentAnswers ? 4 : 2)) - 2 - (this.showParentAnswers ? 0 : 1));
                        doc.text(second, 10 + idWidth + this.centeriseText(second, questionWidth, doc), y + i * rowHeight + ((rowHeight + textHeight) / (this.showParentAnswers ? 4 : 2)) + 1.5 - (this.showParentAnswers ? 0 : 1));
                    }

                    if (index < 4) {
                        doc.setFont(undefined, 'normal');

                        doc.setTextColor(0, 0, 0);
                        text = this.getTextDescription(obj['QuestionId'], obj['AnswerRater']);
                        var textSplit = text.split(" ");

                        if (textSplit.length == 1) {
                            //  doc.text(textSplit[0], 10 + idWidth + questionWidth + scoreWidth + this.centeriseText(textSplit[0], descriptionWidth, doc), y + i * rowHeight + ((rowHeight + textHeight) / 2) + 1);
                            doc.text(textSplit[0], 10 + idWidth + questionWidth + scoreWidth + this.centeriseText(textSplit[0], descriptionWidth, doc), y + i * rowHeight + (rowHeight + textHeight) / 2 - 1);
                        }
                        else {
                            doc.text(textSplit[0], 10 + idWidth + questionWidth + scoreWidth + this.centeriseText(textSplit[0], descriptionWidth, doc), y + i * rowHeight + ((rowHeight + textHeight) / 2) - 3.5);
                            doc.text(textSplit[1], 10 + idWidth + questionWidth + scoreWidth + this.centeriseText(textSplit[1], descriptionWidth, doc), y + i * rowHeight + ((rowHeight + textHeight) / 2) + 1);
                        }
                    }
                    else {
                        var text = this.getAnswer(obj['QuestionId'], obj['AnswerRater']).toString();
                        doc.text(text, 10 + idWidth + questionWidth + this.centeriseText(text, scoreWidth, doc), y + i * rowHeight + (rowHeight + textHeight) / 2 - 1);
                    }

                    i++;
                });


                x = 10;
                y += i * rowHeight + 16;

                doc.setFontSize(13);

                doc.setFont(undefined, 'bold');

                doc.setTextColor(0, 0, 0);
                doc.text("Special Comments - " + category.CategoryName, x, y);

                y += 7;
                x = 10;
                var commentPerLine = [];
                commentPerLine.push("");
                var index2 = 0;
                if (category.HasComment) {
                    category.Comment.split(" ").forEach(word => {
                        if (this.calculateTextWidth(commentPerLine[index2] + " " + word, doc) < 180) {
                            commentPerLine[index2] += " " + word;
                        }
                        else {
                            commentPerLine.push("");
                            index2++;
                            commentPerLine[index2] = word;
                        }
                    });
                }
                else {
                    commentPerLine[0] = "No comments.";
                }

                doc.setFontSize(10);

                doc.setFont(undefined, 'normal');

                var textHeight = doc.getTextDimensions('Text')['h'];
                //doc.setDrawColor(0, 0, 255);
                //doc.setLineWidth(0.2);
                //doc.rect(x, y, 190, commentPerLine.length * (textHeight + 4) + 6);

                doc.setTextColor(0, 0, 0);
                commentPerLine.forEach((line, index2) => {
                    doc.text(line, x + 5, y + (2 + textHeight) * index2);
                })


                index++;
            });

            doc.addPage();

            x = 10;
            y = 20;

            doc.setFontSize(14);

            doc.setFont(undefined, 'bold');

            doc.setTextColor(0, 0, 0);
            doc.text("General Comments", x, y);

            y += 10;
            x = 10;
            var commentPerLine = [];
            commentPerLine.push("");
            index = 0;
            if (this.data.Comment) {
                this.data.Comment.split(" ").forEach(word => {
                    if (this.calculateTextWidth(commentPerLine[index] + " " + word, doc) < 180) {
                        commentPerLine[index] += " " + word;
                    }
                    else {
                        commentPerLine.push("");
                        index++;
                        commentPerLine[index] = word;
                    }
                });
            }
            else {
                commentPerLine[0] = "No comments.";
            }


            doc.setFontSize(10);

            doc.setFont(undefined, 'normal');

            var textHeight = doc.getTextDimensions('Text')['h'];
            //doc.setDrawColor(0, 0, 255);
            //doc.setLineWidth(0.2);
            //doc.rect(x, y, 190, commentPerLine.length * (textHeight + 4) + 6);

            index = 0;
            doc.setTextColor(0, 0, 0);
            commentPerLine.forEach(line => {
                doc.text(line, x + 5, y + (2 + textHeight) * index++);
            })

            doc.save("Student Assessment.pdf");
        }
        else if (this.ShowParentUAData && this.data['ParentQuestionIdMeanValue'].length > 0 && this.parentsAnswersCount > 0) {
            var index = 0;
            //------------------------------------
            [this.data['BasicReading'], this.data['ReadingComprehension'], this.data['Writing'], this.data['ExecutiveSkills'], this.data['GradeLevel']].forEach(category => {
                doc.addPage();

                x = 10;
                y = 16;

                doc.setFontSize(14);

                doc.setFont(undefined, 'bold');

                doc.setTextColor(0, 0, 0);
                doc.text(category['CategoryName'], x, y);

                y += 10;
                var rowHeight = 10;
                var textHeight = doc.getTextDimensions('Text')['h'];
                var questionWidth = 4 + this.calculateTextWidth(category['Questions'].map(a => a['QuestionParent']).reduce((a, b) => a.length > b.length ? a : b, ''), doc)
                if (questionWidth > 128) questionWidth = 128;
                var idWidth = 7;
                var scoreWidth = 17;
                var descriptionWidth = 22;
                var parentWidth = 0;

                if (index < 4) {
                    doc.setDrawColor(0, 0, 255);
                    doc.setFillColor(163, 207, 255);
                    doc.setLineWidth(0.2);
                    var text = "Result";
                    var textWidth = this.calculateTextWidth(text, doc) + 4;
                    doc.rect(x, y, idWidth + questionWidth / 2, rowHeight, 'FD'); //big rectangle on the left containing the word Results

                    doc.setFontSize(10);

                    doc.setFont(undefined, 'bold');

                    doc.setTextColor(0, 0, 0);
                    doc.text(text, x + this.centeriseText(text, idWidth + questionWidth / 2, doc), y + ((rowHeight + textHeight) / 2) - 1);

                    doc.setDrawColor(0, 0, 255);
                    doc.setFillColor(163, 207, 255);
                    doc.setLineWidth(0.2);
                    doc.rect(x + idWidth + questionWidth / 2, y, questionWidth / 2 + scoreWidth + descriptionWidth, rowHeight, 'FD'); //this is the big rectangle on the right


                    doc.setFont(undefined, 'normal');

                    doc.setFontSize(10);
                    var space = 8;
                    var tscore1 = category['CategoryTscoreParent1'];
                    var tscore2 = category['CategoryTscoreParent2'];
                    text = "T-Score Parent 1 = " + tscore1.toString();
                    doc.text(text, x + idWidth + questionWidth / 2 + space - space / 2, y + ((rowHeight + textHeight) / 4));

                    text = "T-Score Parent 2 = " + tscore2.toString();
                    doc.text(text, x + idWidth + questionWidth / 2 + space - space / 2, y + rowHeight / 2 + ((rowHeight + textHeight) / 4));
                    text = this.getLevelByScore(tscore1);
                    this.setBackgroundFillColor(tscore1, doc);
                    doc.setDrawColor(0, 0, 255);
                    doc.setLineWidth(0.2);
                    doc.rect(x + idWidth + questionWidth / 2 + 2 * space + 26, y, 30, rowHeight, 'FD'); //this is the medium rectangle
                    doc.text(text, x + idWidth + questionWidth / 2 + 2 * space + 26 + this.centeriseText(text, 30, doc), y + ((rowHeight + textHeight) / 4));
                    text = this.getLevelByScore(tscore2);
                    this.setBackgroundFillColor(tscore2, doc);
                    doc.text(text, x + idWidth + questionWidth / 2 + 2 * space + 26 + this.centeriseText(text, 30, doc), y + rowHeight / 2 + ((rowHeight + textHeight) / 4));

                    doc.setDrawColor(0, 0, 255);
                    doc.setLineWidth(0.01);
                    doc.line(x + idWidth + questionWidth / 2, y + rowHeight / 2, x + idWidth + questionWidth / 2 + 2 * (space + 25) + space / 2 + 2, y + rowHeight / 2);
                }

                x = 10;
                y += rowHeight;
                rowHeight = 9;

                doc.setDrawColor(0, 0, 255);
                doc.setFillColor(163, 207, 255);
                doc.setLineWidth(0.2);
                doc.rect(x, y, idWidth + questionWidth, rowHeight, 'FD');

                doc.setFontSize(10);

                doc.setFont(undefined, 'bold');

                doc.setTextColor(0, 0, 0);
                text = "Item";
                doc.text(text, x + this.centeriseText(text, idWidth + questionWidth, doc), y + ((rowHeight + textHeight) / 2) - 1);
                x += idWidth + questionWidth;

                doc.setDrawColor(0, 0, 255);
                doc.setFillColor(163, 207, 255);
                doc.setLineWidth(0.2);
                doc.rect(x, y, scoreWidth, rowHeight, 'FD');

                doc.setFontSize(10);

                doc.setFont(undefined, 'bold');

                doc.setTextColor(0, 0, 0);
                text = index < 4 ? "Score" : "Answer";
                doc.text(text, x + this.centeriseText(text, scoreWidth, doc), y + ((rowHeight + textHeight) / 2) - 1);
                x += scoreWidth;

                if (index < 4) {
                    doc.setDrawColor(0, 0, 255);
                    doc.setFillColor(163, 207, 255);
                    doc.setLineWidth(0.2);
                    doc.rect(x, y, descriptionWidth, rowHeight, 'FD');

                    doc.setFontSize(10);

                    doc.setFont(undefined, 'bold');

                    doc.setTextColor(0, 0, 0);
                    var text = "Descriptor";
                    doc.text(text, x + this.centeriseText(text, descriptionWidth, doc), y + ((rowHeight + textHeight) / 2) - 1);
                }

                doc.setFontSize(9);

                var extraRowHeight = 1;
                rowHeight += extraRowHeight;
                y -= extraRowHeight;

                var i = 1;
                category['Questions'].forEach(obj => {
                    doc.setDrawColor(0, 0, 255);
                    doc.setLineWidth(0.2);
                    doc.rect(10, y + i * rowHeight, idWidth, rowHeight);
                    doc.rect(10 + idWidth, y + i * rowHeight, questionWidth, rowHeight);
                    doc.rect(10 + idWidth + questionWidth, y + i * rowHeight, scoreWidth, rowHeight);
                    if (index < 4) {
                        obj['AnswerParent'].forEach((InnerObj, index) => {
                            this.setBackgroundDescription(obj['QuestionId'], InnerObj, doc);
                            doc.rect(10 + idWidth + questionWidth + scoreWidth, y + i * rowHeight + index * (rowHeight / 2), descriptionWidth, rowHeight / this.parentsAnswersCount, 'FD');
                        })
                    }


                    doc.setFont(undefined, 'normal');

                    doc.setTextColor(0, 0, 0);
                    var id = obj['QuestionId'];
                    doc.text(id, 10 + this.centeriseText(id, idWidth, doc), y + i * rowHeight + ((rowHeight + textHeight) / 2) - 1);



                    if (this.calculateTextWidth(obj['QuestionParent'], doc) < questionWidth - 2) {
                        doc.text(obj['QuestionParent'], 10 + idWidth + this.centeriseText(obj['QuestionParent'], questionWidth, doc), y + i * rowHeight + ((rowHeight + textHeight) / (this.showParentAnswers ? 4 : 2)) - (this.showParentAnswers ? 0 : 1));
                    }
                    else {
                        var first = obj['QuestionParent'].split(" ");
                        var second = first.splice(Math.floor(first.length / 2) + 1).join(" ");

                        doc.text(first.join(" "), 10 + idWidth + this.centeriseText(first, questionWidth, doc), y + i * rowHeight + ((rowHeight + textHeight) / (this.showParentAnswers ? 4 : 2)) - 2 - (this.showParentAnswers ? 0 : 1));
                        doc.text(second, 10 + idWidth + this.centeriseText(second, questionWidth, doc), y + i * rowHeight + ((rowHeight + textHeight) / (this.showParentAnswers ? 4 : 2)) + 1.5 - (this.showParentAnswers ? 0 : 1));
                    }

                    if (this.parentsAnswersCount == 1) {
                        doc.text(this.getAnswer(obj['QuestionId'], obj['AnswerParent'][0]).toString(), 10 + idWidth + questionWidth + this.centeriseText(obj['AnswerParent'], scoreWidth, doc), y + i * rowHeight + (rowHeight + textHeight) / 2 - 1);
                    } else if (this.parentsAnswersCount == 2) {
                        doc.line(10 + idWidth + questionWidth, y + i * rowHeight + rowHeight / 2, 10 + idWidth + questionWidth + scoreWidth, y + i * rowHeight + rowHeight / 2)
                        doc.text(this.getAnswer(obj['QuestionId'], obj['AnswerParent'][0]).toString(), 10 + idWidth + questionWidth + this.centeriseText(obj['AnswerParent'][0], scoreWidth, doc), y + i * rowHeight + (rowHeight + textHeight) / 2 - 4);
                        doc.text(this.getAnswer(obj['QuestionId'], obj['AnswerParent'][1]).toString(), 10 + idWidth + questionWidth + this.centeriseText(obj['AnswerParent'][1], scoreWidth, doc), y + i * rowHeight + (rowHeight + textHeight) / 2 + 1.5);
                    }


                    if (index < 4) {

                        doc.setFont(undefined, 'normal');

                        doc.setTextColor(0, 0, 0);

                        if (this.parentsAnswersCount == 1) {
                            text = this.getTextDescription(obj['QuestionId'], obj['AnswerParent'][0]);
                            doc.text(text, 10 + idWidth + questionWidth + scoreWidth + this.centeriseText(text, descriptionWidth, doc), y + i * rowHeight + (rowHeight + textHeight) / 2 - 1);
                        } else if (this.parentsAnswersCount == 2) {
                            text = this.getTextDescription(obj['QuestionId'], obj['AnswerParent'][0]);
                            doc.text(text, 10 + idWidth + questionWidth + scoreWidth + this.centeriseText(text, descriptionWidth, doc), y + i * rowHeight + (rowHeight + textHeight) / 2 - 4);
                            text = this.getTextDescription(obj['QuestionId'], obj['AnswerParent'][1]);
                            doc.text(text, 10 + idWidth + questionWidth + scoreWidth + this.centeriseText(text, descriptionWidth, doc), y + i * rowHeight + (rowHeight + textHeight) / 2 + 1.5);
                        }
                    }

                    i++;
                });


                x = 10;
                y += i * rowHeight + 16;

                doc.setFontSize(13);

                doc.setFont(undefined, 'bold');

                doc.setTextColor(0, 0, 0);
                doc.text("Special Comments - " + category.CategoryName, x, y);

                y += 7;
                x = 10;
                var commentPerLine = [];
                commentPerLine.push("");
                var index2 = 0;
                if (category.HasComment) {
                    category.Comment.split(" ").forEach(word => {
                        if (this.calculateTextWidth(commentPerLine[index2] + " " + word, doc) < 180) {
                            commentPerLine[index2] += " " + word;
                        }
                        else {
                            commentPerLine.push("");
                            index2++;
                            commentPerLine[index2] = word;
                        }
                    });
                }
                else {
                    commentPerLine[0] = "No comments.";
                }

                doc.setFontSize(10);

                doc.setFont(undefined, 'normal');

                var textHeight = doc.getTextDimensions('Text')['h'];
                //doc.setDrawColor(0, 0, 255);
                //doc.setLineWidth(0.2);
                //doc.rect(x, y, 190, commentPerLine.length * (textHeight + 4) + 6);

                doc.setTextColor(0, 0, 0);
                commentPerLine.forEach((line, index2) => {
                    doc.text(line, x + 5, y + (2 + textHeight) * index2);
                })


                index++;
            });

            doc.addPage();

            x = 10;
            y = 20;

            doc.setFontSize(14);

            doc.setFont(undefined, 'bold');

            doc.setTextColor(0, 0, 0);
            doc.text("General Comments", x, y);

            y += 10;
            x = 10;
            var commentPerLine = [];
            commentPerLine.push("");
            index = 0;
            if (this.data.Comment) {
                this.data.Comment.split(" ").forEach(word => {
                    if (this.calculateTextWidth(commentPerLine[index] + " " + word, doc) < 180) {
                        commentPerLine[index] += " " + word;
                    }
                    else {
                        commentPerLine.push("");
                        index++;
                        commentPerLine[index] = word;
                    }
                });
            }
            else {
                commentPerLine[0] = "No comments.";
            }


            doc.setFontSize(10);

            doc.setFont(undefined, 'normal');

            var textHeight = doc.getTextDimensions('Text')['h'];
            //doc.setDrawColor(0, 0, 255);
            //doc.setLineWidth(0.2);
            //doc.rect(x, y, 190, commentPerLine.length * (textHeight + 4) + 6);

            index = 0;
            doc.setTextColor(0, 0, 0);
            commentPerLine.forEach(line => {
                doc.text(line, x + 5, y + (2 + textHeight) * index++);
            })

            doc.save("Student Assessment.pdf");
        }
        else if (this.ShowParentUAData && this.data['ParentQuestionIdMeanValue'].length == 0) {
            this.authService.alertError("Parents/Guardians assessment was not allowed for the current UA Period.");
        }
        else if (this.ShowParentUAData && this.parentsAnswersCount == 0) {
            this.authService.alertError("This student was not assessed by parents/guardians for the current UA Period.");
        }
    }

    centeriseText(text, width, doc) {
        var str = text != null ? text.toString() : "";
        return (width - this.calculateTextWidth(str, doc)) / 2;
    }
    calculateTextWidth(tw, doc) {
        return doc.getStringUnitWidth(tw) * doc.getFontSize() / (72 / 25.6);
    }

    getLevelByScore(score) {
        if (score) {
            if (score < 40) return this.globals.highRisk;
            else if (score < 46) return this.globals.atRisk;
            else if (score < 61) return this.globals.typical;
            else if (score < 100) return this.globals.strength;
        }
        else return "-";
    }
    setBackgroundFillColor(value, doc) {

        if (value) {
            if (value < 40) doc.setFillColor(255, 51, 0);
            else if (value < 46) doc.setFillColor(255, 255, 0);
            else if (value < 61) doc.setFillColor(169, 208, 142);
            else doc.setFillColor(112, 173, 71);
        }
        else doc.setFillColor(108, 117, 125);

    }

    setBackgroundDescription(id, answer, doc) {
        if (this.ShowTeacherUAData) {
            if (answer > Math.round(this.data['RaterQuestionIdMeanValue'][id - 1] + this.data['RaterQuestionIdStDev'][id - 1])) return doc.setFillColor(112, 173, 71);
            else if (answer < Math.round(this.data['RaterQuestionIdMeanValue'][id - 1] - this.data['RaterQuestionIdStDev'][id - 1])) return doc.setFillColor(255, 255, 0);
            else return doc.setFillColor(169, 208, 142);
        }
        else if (this.ShowParentUAData) {
            if (answer > Math.round(this.data['ParentQuestionIdMeanValue'][id - 1] + this.data['ParentQuestionIdStDev'][id - 1])) return doc.setFillColor(112, 173, 71);
            else if (answer < Math.round(this.data['ParentQuestionIdMeanValue'][id - 1] - this.data['ParentQuestionIdStDev'][id - 1])) return doc.setFillColor(255, 255, 0);
            else return doc.setFillColor(169, 208, 142);
        }
    }

    getTextDescription(id, answer) {
        if (this.ShowTeacherUAData) {
            if (answer > Math.round(this.data['RaterQuestionIdMeanValue'][id - 1] + this.data['RaterQuestionIdStDev'][id - 1])) return 'Strength';
            else if (answer < Math.round(this.data['RaterQuestionIdMeanValue'][id - 1] - this.data['RaterQuestionIdStDev'][id - 1])) return 'AtRisk';
            else return 'Typical';
        }
        else if (this.ShowParentUAData) {
            if (answer > Math.round(this.data['ParentQuestionIdMeanValue'][id - 1] + this.data['ParentQuestionIdStDev'][id - 1])) return 'Strength';
            else if (answer < Math.round(this.data['ParentQuestionIdMeanValue'][id - 1] - this.data['ParentQuestionIdStDev'][id - 1])) return 'AtRisk';
            else return 'Typical';
        }
    }
}

